.SapLibraryContainer {

    margin: 0 auto;
    padding: 0px 120px;
}

.SapLibraryContainer .heading {


    margin-bottom: 40px;
    color: #394B99;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    /* 140% */
}

.SapLibraryContainer .filters {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.SapLibraryContainer .filters .dropdown {
    height: 60px;
}

.dropdown,
.search {
    border-radius: 17px;
    border: 1px solid #DADADA;
    background: #FFF;
    cursor: pointer;
    flex: 1;
    padding: 10px;

    font-size: 1rem;
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin: 40px 0px;

}

.libraryCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 287px;
    padding: 20px;
    cursor: pointer;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
}

.image_container {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardImage {
    width: 100%;
    max-width: 200PX;

    object-fit: cover;
}

.cardLabel {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.noDataFound {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    line-height: normal;
    padding: 5rem 20px;
}

.loadMore {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 3rem;

}

.loadMore>button {
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
}

@media(max-width:600px) {
    .SapLibraryContainer {
        padding: 0px;
    }

    .SapLibraryContainer .filters {
        flex-wrap: wrap;
    }

    .search {
        flex-wrap: wrap;
        height: 38px;
    }
}