/* Main container */
.systemspageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;


   
  }
  
  /* Content container */
 .systemspageContainer .contentContainer {
    text-align: center;
    max-width: 800px;
    width: 100%;
 
    
 
  }
  .searchimgwrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    background: linear-gradient(0deg, #E9EFF7 0%, #FBEFFB 96.11%);
  }
  .contentContainer >img{
    width: 100%;
    max-width: 400px;
  }
  /* Heading styles */
 .systemspageContainer .contentContainer .heading {
   
        color: #394b99;
        font-family: "Inter-SemiBold", Helvetica;
        font-size: 40px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: 56px;
        text-align: center;     
  }
  
  /* Description styles */
 .systemspageContainer .contentContainer  .description {
       margin-top: 46px;
        color: #000000;
        font-family: "Inter-Regular", Helvetica;
        font-size: 16px;
        font-weight: 400;
        left: 0;
        letter-spacing: 0;
        line-height: 28px;
      
  }
  
  .imageContainer{
    position: relative;
   
    margin-bottom: 100px;
  }
  .center_bolt{
    position: absolute;
    top: 34%;
    left: 46%;
    transform: translate(-50%, -50%);
    animation: rotate infinite 2s linear;
}
  .bolts_grey{
    position: absolute;
    top: 0%;
    left: 24%;

    animation: rotate3 infinite 2s linear;
  }
  .bolts_grey2{
    position: absolute;
    bottom: 0%;
    right: 24%;

    animation: rotate3 infinite 2s linear;
  }
  .bolts_greysm{
    position: absolute;
    top: 26%;
    left: 24%;

    animation: rotate2 infinite 2s linear;
  }
  .bolts_greysxm{
    position: absolute;
    top: -5%;
    left: 34%;

    animation: rotate3 infinite 3s linear;
  }
  .bolts_greyl{
    position: absolute;
    bottom: -8%;
    right: 38%;

    animation: rotate2 infinite 2s linear;
  }
  .bolts_greysmm{
    position: absolute;
    bottom: 12%;
    right: 19%;

    animation: rotate2 infinite 3s linear;
  }
  .bolts_greyxsmm{
    position: absolute;
    bottom: -13%;
    right: 27%;
    animation: rotate3 infinite 2s linear;
  }
  /* Image styles */
 .systemspageContainer .contentContainer .image {
    max-width: 100px; /* Make the image responsive */
    height: auto;
    margin-top: 1rem;
    object-fit: contain;
  }
/* 



  ----------------------seach container------------------------- */
  
  .SearchContainer {
    padding: 50px 120px;
    display: flex;
    
    
    justify-content: flex-end; /* Align the search component centrally */
}

.cardsGridContainer{
 
  padding: 0px 120px;
  padding-top: 136px;
  padding-bottom: 180px;
}

/* ---------------botttom  imagee------------ */


.imageGridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px; /* Adds space between grid items */
  
    padding: 0px 120px;
    padding-bottom: 66px;
   
  }
  
  .imageGridItem {
      width: 100%;
      height: auto;
      min-width: 150px;
      min-height: 150px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: #FFF;
      cursor: pointer;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      /* Ensures images are clipped to fit the container */
  }
  .imageGridItem img {
     width: auto;
     max-width: 100px;
   
  }
  


  /* ------------------cards grid-------------- */


  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate3 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @media(max-width:850px){
    .bystemsimg{
      width: 100%;
    }
    .imageContainer{
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  @media(max-width:600px){

.cardsGridContainer{
  padding-left: 30px;
  padding-right: 30px;
}

    .SearchContainer{
      padding: 50px;
    }
    .imageGridContainer{
      display: grid      ;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
       
          padding: 0px 30px;
          padding-bottom: 66px;
    }
    .center_bolt{
      top: 31%;
    left: 42%;
    width: 50px;
    height: 50px;
    }
    .bolts_grey2{
      bottom: -3%;
      right: 24%;
    }
    .bolts_grey{
      top: -1%;
    left: 19%;
    }
    .bolts_greysm{
      top: 26%;
    left: 16%;
    }
  }