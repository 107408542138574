.footerMain {
  /* max-width: 1200px; */
  margin: auto;
  position: relative;
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.imageHolder {
  padding: 0px 0px 0px 0px;
}

.imageHolder img {
  display: block;
  /* Ensures the image behaves as a block element */
  width: 100%;
  /* Optionally, make the image take full width of its container */
  height: auto;
  /* Keeps the aspect ratio of the image */
}

.footerMain img {
  width: 100%;
  /* Ensure the wave image spans the full width */
  height: auto;
  /* Maintain aspect ratio */
  display: block;
  /* Removes any unwanted inline spacing */
}

.footerHeader {
  display: flex;
  padding: 30px 120px;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

}

.footerTopLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  /* padding-left: 120px; */

}

.footerLogo>img {
  width: 143px;
  height: 45px;
}

.footerTagline {
  font-weight: 600;
  margin-top: 20px;
  font-size: 24px;
  line-height: 38px;
  text-align: justify;
}

.footerNav {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* Add space between each ul */

}

.footerNav .navList>a {
  text-decoration: none;
}

.navList {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  margin: 0 20px;
  width: 50%;

}

.navItem {
  margin-bottom: 10px;
  text-align: left;
  padding: 5px;
}

.navItem a {
  text-decoration: none;
  /* Remove underline for links */
  color: inherit;
  /* Ensure links inherit text color */
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}


@media (max-width: 917px) {
  .navList {

    margin: 0px 0px;

  }

}

@media (max-width: 890px) {
  .navList {
    display: grid;

    margin: 0px 0px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(146px, 1fr));
  }

  .navItem {
    text-align: left;
  }
}

@media (max-width: 600px) {
  .navList {
    margin: 0px;
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
  }

  .footerTopLeft {
    padding-left: 0px;
  }

  /* .navItem{
  text-align: -webkit-center;
} */
}