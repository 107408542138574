.App {
  text-align: center;
}
@font-face {
  font-family: 'AstonPoliz'; /* Define the font name explicitly */
  font-style: normal;
  font-weight: 400;
  src: local('AstonPoliz'), url('https://fonts.cdnfonts.com/s/110062/Astonpoliz.woff') format('woff');
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rmb_run{
  color: #394B99;
  font-family: "Red Hat Display";
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
}
.rmb_my {
  color: #394B99;
  font-family: "Red Hat Display";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

.rmb_bot {
  color: #394B99;
  font-family: "Red Hat Display";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}
.data_wrangler {
  font-family: 'AstonPoliz';
}
.rmb_run_p{
  color: #000;
  font-family: "Red Hat Display";

font-style: italic;
font-weight: 700;
line-height: 32px; 
}
.rmb_my_p{
  color: #000;
  font-family: "Red Hat Display";

font-style: normal;
font-weight: 400;
line-height: 32px;
}
.rmb_bot_p{
  color: #000;
  font-family: "Red Hat Display";

font-style: normal;
font-weight: 700;
line-height: 32px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slick-slide{
overflow:  hidden !important;
}