.Signincontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(to right, #f3f4fe, #fef3fc); */
    padding: 0px 120px;
  }
  
  .Signincontainer .title {
    font-size: 32px;
    font-weight: bold;
    color: #394B99;
    margin-bottom: 20px;
  }
  
  .formContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    padding: 20px;
    border-radius: 12px;
  }
  
  .signinform {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .signinform .inputRow {
    display: flex;
    gap: 20px;
    max-width: 75%;
  }
  
  .inputGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup .label {
    font-size: 14px;
    color: #404040;
    margin-bottom: 8px;
  }
  
  .inputGroup .textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 24px;
    background-color: #ffffff;
    transition: border 0.3s ease;
    box-shadow: 0px 0px 10px 0px #00000014;
  }
  
  .inputGroup .textarea:focus {
    outline: none;
    border-color: #2b2b73;
  }
  
  .inputGroup .textarea {
    height: 100px;
    resize: none;
  }
  
  .SignIninput {
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    transition: border-bottom 0.3s ease;
  }
  
  .SignIninput:focus {
    outline: none;
    border-bottom: 2px solid #2b2b73; /* Highlight color on focus */
  }
  
  .SignIninput::placeholder,
  .textarea::placeholder {
    color: #C0C0C066; /* Placeholder color */
  }
  
  .buttonRow {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 30px 0px;
    max-width: 75%;
  }
  
  .signinButton {
    color: #ffffff;
    padding: 12px 24px;
    font-size: 16px;
    background-color: #4F63BE;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signinButton:hover {
    background-color: #4040c5;
  }
  
  .loginButton {
    border: none;
    background-color: #f3f4fe;
    color: #a2a2a2;
    font-weight: 600;
  }
  
  .loginButton:hover {
    background-color: #f3f4fe;
  }
  
  .partnerimageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .partnerimageContainer .image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  