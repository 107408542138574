/* Parent Container */
.partnerContainer {
  /* margin: 0px 120px 160px; */
  margin-bottom: 160px;
}

.partnerSection1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  padding-top: 125px;
  /* max-width: 1200px; 
    margin: 180px auto auto;  */

}

/* Text Section */
.partnerSection1 .textSection {

  max-width: 600px;
  /* Limit the width of the text */
}

.partnerSection1 .textSection h1 {
  font-family: 'Inter';
  font-size: 32px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  text-underline-position: 'from-font';
  text-decoration-skip-ink: none;
  color: #394B99
}

.partnerSection1 .textSection p {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 30px;

}

/* Image Section */
.partnerSection1 .imageSection {
  flex: 1;
  /* Takes up the remaining 50% */
  min-width: 300px;
  text-align: right;
  /* Align the image to the right */
}

.partnerSection1 .imageSection .image {
  max-width: 100%;
  /* Ensure the image doesn't overflow */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 8px;
  /* Optional: Add rounded corners */
}

.partnerSection1 .textSection .ExpRmbButton {
  border-radius: 40px;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: normal;
  background-color: rgba(79, 99, 190, 1);
  color: #fff;
  cursor: pointer;
  margin: auto;
  padding: 0px 20px;
  /* padding: 20px; */

  height: 61px;
  border: none;
  font-family: 'Inter';

}



/* partner section 2 counter */

.partnerSection2 {
  margin: 4rem 0px;
}


/* partner section 3 our partneres world */

.ourPartnersSection3 {

  text-align: center;
}

.ourPartnersSection3>span {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;

}

.ourPartnersSection3>p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  padding-top: 40px;

}

.ourPartnersSection3 .explorefurther {
  display: flex;
  align-items: center;

}

.ourPartnersSection3 .explorefurther>button {
  background: rgba(79, 99, 190, 1);
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  padding: 20px 20px;
  color: #fff;
  margin: auto;
  border-radius: 128px;
  width: 170px;
  border: none;

}

/* partner section 4 Let’s get down to business.*/

.ourPartnersSection4 {
  margin-top: 100px;
  text-align: center;
}

.ourPartnersSection4>span {
  color: #394B99;
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.ourPartnersSection4>p {
  color: #000;
  margin-top: 32px;
  margin-bottom: 40px;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */

}

.ourPartnersSection4 .explorefurther {
  display: flex;
  align-items: center;

}

.ourPartnersSection4 .explorefurther>button {
  background: rgba(79, 99, 190, 1);
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  padding: 20px 20px;
  color: #fff;
  margin: auto;
  border-radius: 128px;
  width: 170px;
  border: none;

}

.ourPartnersSection4>img {
  width: 100%;
  max-width: 650px;
  margin-bottom: 40px;
}

/* partner section 5 */
.card {
  display: flex;
  justify-content: flex-end;
  /* Align content to the right */


  border-radius: 8px;


  gap: 2rem;
}


/* Card content */
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Align all content to the right */
  gap: 1rem;
  padding: 2rem;
  text-align: left;
  /* Text aligns to the right */
  background-color: rgba(246, 250, 255, 1);
  border-radius: 25px
}

/* Card label */
.cardLabel {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

/* Card description */
.cardDescription {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

/* Card button */
.cardButton {
  padding: 0.5rem 1rem;
  background: rgba(79, 99, 190, 1);
  color: #fff;
  border: none;
  border-radius: 40px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cardButtonContainer {
  display: flex;
}

.cardButton:hover {
  background: rgba(55, 75, 140, 1);
}


.ourPartnersSection6 {

  text-align: center;
  overflow: hidden;
}

.ourPartnersSection6>span {
  color: #394B99;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;

  font-weight: 600;
  line-height: normal;
}

.ourPartnersSection6>p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */

}

.ourPartnersSection5 {
  margin-bottom: 180px;
}

@media(max-width:1000px) {

  .ourPartnersSection5 .card {
    flex-wrap: wrap;
  }

  .partnerSection1 .imageSection {
    text-align: left;
  }
}

@media(max-width:600px) {
  .partnerContainer {
    margin: 0px 30px 160px;
  }

  .ourPartnersSection5 .card {
    flex-wrap: wrap;
  }

  .cardButton {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .cardButtonContainer {
    display: flex;
    flex-direction: column;
  }

}

@media(max-width:500px) {
  .partnerContainer {
    margin: 0px 0px 160px;
  }

}