/* Main container */
.connectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 0px 2rem 0px; */

  margin-bottom: 160px;
  margin-top: -85px;
}

/* Content container */
.contentContainer {
  text-align: center;

  width: 100%;
  /* padding:0px  2rem; */

  background: linear-gradient(180deg, rgba(253, 254, 254, 0.65) 23.93%, rgba(185, 193, 229, 0.65) 61.72%, rgba(255, 255, 255, 0.65) 101.35%);


}

.contentContainer_inner {
  max-width: 800px;
  margin: auto;
  margin-bottom: 110px;
  margin-top: 80px;
}

/* Heading styles */
.contentContainer_inner .heading {
  font-size: 32px;
  font-weight: 600;

  color: rgba(79, 99, 190, 1);
  margin-bottom: 24px;
}

/* Description styles */
.contentContainer_inner .description {
  font-size: 16px;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 80px;
}

/* Image styles */
.contentContainer .image {
  max-width: 100%;
  /* Make the image responsive */
  height: auto;
  margin-top: 1rem;
  object-fit: contain;
}


/* -----------------contant container2------------- */
/* .contentContainer2 {
  padding: 0px 120px;
} */








/* --------------------features section--------------------- */
/* Features container    --------------         section--------*/
.featuresContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3rem;
  width: 100%;

}

/* Each feature section */
.featureSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5rem;
  padding: 2rem 0px;

}

.leftImage {
  flex-direction: row;
}

.rightImage {
  flex-direction: row-reverse;
}

/* Text content styles */
.textContent {
  flex: 1;
}

.featureLabel {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
  color: rgba(79, 99, 190, 1);
  position: relative;
  text-align: left;
}



.featureDescription {
  color: #000;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

/* Image container styles */
/* Image container styles */
.imageContainer {
  flex: 1;
  display: flex;
}

.leftImage .imageContainer {
  justify-content: flex-end;
  /* Align image to the left */
}

.rightImage .imageContainer {
  justify-content: flex-start;
  /* Align image to the right */
}

.featureImage {
  max-width: 400px;
  min-width: 200px;
  /* Set maximum width for the image */
  height: auto;
  /* Maintain aspect ratio */
  width: 100%;
  object-fit: cover;
  /* Ensure image fits nicely */
}

@media(max-width:600px) {
  /* .contentContainer2 {
    padding: 0px 30px;
  } */
}