/* Container holding content and scrollbar */
.scroll-container {
    display: flex;
    position: relative;
    overflow: hidden; /* Hide the default scrollbar */
  }
  
  /* Scrollable content area */
  .content {
    width: calc(100% - 50px); /* Adjust based on scrollbar size */
    overflow-y: scroll; /* Enable scrolling */
    height: 100%;
    padding: 10px;

    box-sizing: border-box;
  }
  
  /* Hide the default scrollbar in webkit browsers (Chrome, Safari) */
  .content::-webkit-scrollbar {
    display: none;
  }
  
  /* Custom scrollbar container */
  .scrollbar {
    width: 50px;
    position: relative;
   
    justify-content: center;
  }
  
  /* Draggable thumb */
  .scroll-thumb {
    position: absolute;
    cursor: pointer;
    transform: translate(-50%, -50%);
  }
  