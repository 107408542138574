.caseStudiesContainer {
  width: 100%;

  margin: 0 auto;

  padding: 2rem 0rem 160px;

  /* text-align: center; */
}

.cardRight {
  /* width: 450px;
    height: 200px; */
  position: relative;
  width: 50%;
  /* Adjust as per your layout */
  height: 50%;
  /* Adjust as per your layout */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Ensures no overflow */
  transition: transform 1s ease-in-out;
}

.mainHeading {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  margin-bottom: 5rem;
  text-align: center;
}

.caseStudyCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  /* padding: 2rem; */
  transition: transform 0.3s ease;
}

/* .caseStudyCard:hover {
    transform: scale(1.02);
  } */

.cardLeft {
  /* flex: 1;
    padding: 0rem 2rem; */
  position: relative;
  width: 50%;
  /* Adjust the percentage to allocate space */
  padding: 16px;
  /* Add padding for inner spacing */
  box-sizing: border-box;
  /* Include padding in width/height calculation */
  display: flex;
  flex-direction: column;
  /* Align content vertically */
  justify-content: center;
  /* Center content vertically */
}

.cardHeading {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 1rem;
  position: relative;
}

.cardHeading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1.2rem;
  /* Adjust this value for the distance between the label and the underline */
  width: 80px;
  height: 3px;
  background-color: rgba(79, 99, 190, 1);
  /* Underline color */
  animation: growUnderline 3s;
  /* Animation applied */
}

.cardDescription {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6d6d6d;


}

/* .readMoreBtn {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    cursor:pointer;
    background-color: #ffff;
  }
   */
.readMoreBtn {
  display: flex;
  align-items: center;
  /* Vertically center the text and the icon */
  justify-content: center;
  /* Optional: to center content inside the button */
  padding: 5px 10px;
  /* Adjust button padding as needed */
  background: none;
  /* Example button color */
  border: none;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.arrowIcon {
  margin-left: 10px;
  /* Space between the text and the arrow */
  font-size: 1.5em;
  /* Adjust the size of the arrow icon */
}

/* .cardRight {
    flex: 1;
 
  } */
.buttonRow {
  display: flex;
  justify-content: flex-end;
  /* Aligns the button to the right */
  margin-top: 10px;
  /* Optional: Add some spacing above the button */
}

.caseStudyImage {
  max-width: 100%;
  /* max-height: 50%; */
  object-fit: cover;
  transform: translateX(80px);
  /* width: 85%;
    height: auto;
    max-width: 450px;
    margin-left: 60px;
    border-radius: 8px;
    margin-top: -40px; */
}

.loadMore {
  display: flex;
  justify-content: center;

}

@media(max-width:900px) {
  .caseStudyCard {
    flex-direction: column-reverse;
  }

  .cardLeft {
    width: 100%;
  }

  .caseStudyImage {
    max-width: 100%;
    transform: none;
  }

  .cardRight {
    width: 100%;
  }
}