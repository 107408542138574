.container {

  margin: 0px auto 0px;
  padding: 0px 120px;
  position: relative;
  overflow: hidden;

}

.leftCurve {
  position: absolute;
  left: 0;
  z-index: -1;
}

.rightCurve {
  right: 0;
  position: absolute;
  z-index: -1;
}

.heading {


  margin-bottom: 50px;
  color: #394B99;
  text-align: center;
  margin-top: 0px;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 140% */
}

.filters {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}



.dropdown {
  border-radius: 17px;
  border: 1px solid #DADADA;
  background: #FFF;
  cursor: pointer;
  flex: 1;
  padding-left: 10px;
  font-size: 1rem;
  height: 60px;
  appearance: none;
  /* Hides default browser dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='gray'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
  /* Position arrow */
  background-size: 18px;
}

.search {
  border-radius: 17px;
  border: 1px solid #DADADA;
  background: #FFF;
  cursor: pointer;
  flex: 1;
  padding-left: 10px;
  font-size: 1rem;
  height: 60px;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 20px;
  margin: 40px 0px;
  /* margin-bottom: 100px; */
}

.libraryCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 287px;
  padding: 20px;
  cursor: pointer;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
}

.libraryCard:hover {
  box-shadow: 0 12px 16px #0000003d, 0 17px 50px #00000030;
  transform: scale(1.01);
}

.image_container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 100%;
  max-width: 275px;

  object-fit: cover;
}

.cardLabel {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.noDataFound {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  padding: 5rem 20px;
}

.loadMore {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 3rem;

}

.loadMore>button {
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
}

@media(max-width:1190px) {
  .cardGrid {

    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media(max-width:600px) {
  .container {
    padding: 0px 30px;
  }
}