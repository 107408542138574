.fullTitle {
  width: 100%;
  display: flex;
  /* Use flexbox for layout */
  justify-content: space-between;
  /* Align text to left and image to right */
  align-items: center;
  /* Vertically align items */
  background: linear-gradient(180deg, #FFFFFF 43.9%, #E7ECF6 100%);
  /* Linear gradient background */
  /* Add some padding */
  border-radius: 8px;
  /* Optional: add rounded corners */
  text-align: center;

  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  /* color: #333; */
  flex-direction: row;
  flex: 1 1;
  border-radius: 10px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.03);
}

.fullTitle_content>p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  flex: 1;
  /* Allow text to take up available space */

  color: #4A4A4A;
  /* Set text color */
  margin: 0;
  /* Remove any default margin */

  align-self: flex-start;
}

.fullTitle img {
  /* Set the image width */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 8px;
  /* Optional: round image corners */
}

.bottomname {
  color: #404040;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.bottomDesignmation {
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.dots_fullTitle {
  display: flex;
  flex-direction: column;
}

/* Arrows for navigation */

.leftButton,
.rightButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;

  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;


}

.leftButton:hover,
.rightButton:hover {
  background-color: #f0f0f0;
}

.leftButton {
  left: -50px;
  /* Adjust to position outside the carousel */
}

.rightButton {
  right: -50px;
  /* Adjust to position outside the carousel */
}

.fullTitle_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 40px 40px 0px;
}

.flex_container img {
  max-height: 100%;
  object-fit: contain;
  /* Ensures the whole image is visible */
}

.shrinkedImage {
  height: 300px;
  overflow: hidden;
}

.testmonial_image {
  max-width: 300px;

  /*Set the maximum width for the image */
  height: auto;
  /* Maintain the aspect ratio of the image */
  display: block;
  /*Ensure the image is block-level for alignment */
  width: 100%
}

.testmonial_image_container {

  background-color: #FFFFFF;
  /* White background color */
  display: flex;
  /*Use flexbox to align content inside the bottom box*/
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  /* Space the paragraphs left and right*/
  padding: 30px;
  /* Padding inside the bottom box*/
}

@media (max-width: 1200px) {
  .fullTitle_content {
    flex-direction: column;
  }

  .shrinkedImage {
    height: auto;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .card.center {
    width: 250px;
  }

  .testimonialMain {
    max-width: 85%;
  }

  .clientInfo {
    padding-left: 9px;
  }

  .fullTitle_content {
    padding: 40px 20px 0px;
  }

  .testmonial_image_container {
    padding: 20px;
  }

  .shrinkedImage {
    height: auto;
    overflow: hidden;
  }
}