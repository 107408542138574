.solutions {

  max-width: 1440px;
  margin: 0px auto 0px;
}

.solutions .solutionHeader {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #394B99;
  justify-content: center;
}

.solutions .solutionImage {
  display: flex;
  justify-content: center;
  margin: 50px auto 160px;
}

.solutions .solutionGroup {

  display: grid;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  gap: 3vh;
  margin: 0px auto 160px;
  padding-left: 120px;
  padding-right: 120px;
}

.solutionGroup .solutionContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

}

.imageWrapper img {
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.solutionContainer .imageWrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none
}

.solutionContainer>h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-bottom: 16px;
}

.solutionContainer>p {
  width: 263px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
}

@media(max-width:1289px) {
  .solutions .solutionGroup {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media(max-width:878px) {
  .solutions .solutionGroup {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media(max-width:600px) {
  .solutions .solutionGroup {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 1290px) and (max-width: 1460px) {
  .solutions .solutionGroup {
    grid-template-columns: repeat(3, minmax(50px, 1fr));
  }
}