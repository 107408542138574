.careerContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px ;
    
  }
  
.careerContainer  .card {
    min-height: 200px;   
    padding: 20px 35px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    padding-bottom: 15px;
   
    border-bottom: 1px solid #E8E8E8;
  }
  
  .cardHeader .title {
    color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  .applyButton {
    background-color: #4f63be;
      color: #fff;
      padding: 15px 45px;
      border: none;
      border-radius: 40px;
      cursor: pointer;
      color: #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  }
  
  .applyButton:hover {
    background-color: #3e51a1;
  }
  
  .summary {
    font-size: 16px;
    color: #000000;
    padding: 10px 0px;
   
  }
  
  .cardContents > p{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    margin-bottom: 0px !important;
  }
  .buttonRow {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    
  }
  
  .readMoreButton {
    padding: 8px 16px;
      font-size: 16px;
      color: #000000;
      background-color: #FFFFFF;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      color: #000;
      font-family: Inter;
    display: flex;
    align-items: center;
    gap: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    
  }
  
  .readMoreButton:hover {
   cursor: pointer;
  }
  .expandedDescription {
    line-height: 32px;
  }