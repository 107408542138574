.platform{
   margin: 0px auto 160px;
   max-width: 1440px;
}

.platform .platformHeader{
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 40px;
     font-weight: 600;
    line-height: 48.41px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #394B99;
    justify-content: center;

}
.platform .platformImage{
    display: flex;
    justify-content: center;
    margin: auto;
}

.platformGroup {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of images to the next row if necessary */
  justify-content: center; /* Align items horizontally to the center */
  align-items: center; /* Align items vertically to the center */
  text-align: center; /* Centers the text below each image */
  gap: 20px; /* Adds spacing between the items */
  margin: 0 auto; /* Centers the container itself within its parent */
}
.platform .platformGroup{
    align-items: center;

    display: grid;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    gap: 5vh;
    padding: 0px 120px;
    margin: 170px auto 0px;
}
.platformGroup .platformContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .platformContainer .imageWrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .imageWrapper img {
    transition: opacity 0.3s ease;
    cursor: pointer;

  }
  .platformContainer  >h3{
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#000000;
  }
.platformContainer >p {
  width: 263px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#000000;
  }

 .solutionContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .solutionContainer >p{
  height: 40px;
 }
  .platformWrapper {
    position: relative; /* Allow absolute positioning of child elements */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 73px; /* Adjust margin as needed */
  }
  
  .wheel {
    position: absolute;
    top: 20%; /* Center vertically */
    /* transform: translateY(-50%) rotate(0deg);  */
    z-index: -1; /* Place wheels behind the platform image */
    
  }
  
  /* Left-side wheels */
  .wheel.left {
    left: 37%; /* Adjust positioning to left of platform */
    animation: spin 10s linear infinite; 
  }
  
  /* Right-side wheels */
  .wheel.right {
    right: 37%; /* Adjust positioning to right of platform */
    animation: spinAnticlockwise 10s linear infinite; 
  }
  
  /* Specific styles for each wheel */
  .wheel.pink.left {
    width: 80px;
    top:27.5%;
    animation-duration: 10s; /* Medium rotation speed */
  }
  .wheel.pink.right {
    width: 80px;
    top:17.5%;
    animation-duration: 10s; /* Medium rotation speed */
  }
  /* .wheel.grey {
    width: 50px;
    top:30%;
    
  } */
  .wheel.grey.left{
    left: 34%;
    top: 34%;
    animation: spinAnticlockwise 10s linear infinite; 
  }
  .wheel.grey.right{
    top: 40%;
    right: 34%; 
    animation: spin 10s linear infinite; 
  }
  .wheel.outline {
    width: 60px;
    
    animation: spin 10s linear infinite; 
  }
  /* Left-side wheel outline */
  .wheel.outline.left {
    top: 9%;
    left: 33%;
    animation: spinAnticlockwise 10s linear infinite; 
  }
  /* Right-side wheel outline */
  .wheel.outline.right {
    top: 19%;
    right: 33%;
    animation: spin 10s linear infinite;
  }
  @media(max-width:1020px){
    .platform .platformGroup{
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  @media(max-width:600px){
    .platform .platformGroup{
      padding: 0px 30px;
    }
    .wheel.pink.left{
      width: 50px;
      top: 21.5%;
      left: 19%;
    }
    .wheel.pink.right{
      width: 50px;
      top: 12.5%;
      right: 20%;
    }
    .wheel.outline.left{
      top: -9%;
    width: 40px;
    left: 22%;
    }
    .wheel.outline.right{
      top: -1%;
      width: 40px;
      right: 26%;
    }
    .wheel.grey.left{
      left: 32%;
      width: 30px;
      top: 3%;
    }
    .wheel.grey.right{
      top: 13%;
    right: 32%;
    width: 30px;
    }
  }
  /* Keyframes for spinning */
  @keyframes spin {
    from {
      transform: translateY(-50%) rotate(0deg);
    }
    to {
      transform: translateY(-50%) rotate(360deg);
    }
  }
  @keyframes spinAnticlockwise {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg); /* Negative value for anticlockwise rotation */
    }
  }