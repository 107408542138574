/* Wrapper for the entire page */
.workflowspageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* padding-bottom: 160px; */
}

/* Heading Style */
.workflowspageContainer .heading {
  font-size: 40px;
  font-weight: bold;
  color: #394b99;
  text-align: center;
  margin-bottom: 140px;
}

/* Content Section */
.workflowspageContainer .contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15vh;
  flex-wrap: wrap;
  width: 100%;
}

.contentContainer .imageContainer {

  max-width: 500px;
}

.imageContainer .image {
  width: 100%;
  height: auto;
  border-radius: 12px;

}

.textContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.textContainer .label {
  color: #272727;
  font-kerning: none;
  font-family: Inter;
  font-size: 24px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
}

.description {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

/* Grid Section */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Adjust for 3 items per row */
  gap: 50px;
  margin-top: 180px;
  margin-bottom: 129px;
  width: 100%;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.gridImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gridLabel {
  color: #272727;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 200% */
}

.createWorkflow {
  margin-top: 40px;
  flex-wrap: wrap;
  display: flex;
  gap: 27px;
  width: 100%;
  justify-content: space-between;
}

.createWorkflow>span {
  color: #272727;
  text-align: left;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 100% */
  margin: auto 0px;

}

.createWorkflowBtn {
  cursor: pointer;
  border-radius: 60px;
  background: #E27DDD;
  padding: 30px;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  border: none;
  line-height: 24px;
  /* 66.667% */
}

.noDataFound {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
  padding: 5rem 20px;
}

.loading_div {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  padding: 5rem 20px;
}

@media(max-width:990px) {
  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 90px;
    margin-bottom: 90px;
  }
}

@media(max-width:600px) {
  .createWorkflowBtn {
    font-size: 22px;
  }

  .createWorkflow>span {
    font-size: 20px;
    line-height: 1.5;
  }
}