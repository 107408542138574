
.whyActionfi {
  position: relative; /* To position the pseudo-element relative to the container */
  display: flex;
  flex-direction: column;

  /* background-image: url('../../Assets/Images/WhyActionfi/whyafibg.svg'); */
  background-size: 150%;
  width: 100%;
  object-fit: cover;
  padding-top: 5rem;
  // min-height: 100vh;

}
.whyActionfi video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container */
  z-index: -1; /* Places the video behind content */
}
.whyActionfiMain{
max-width: 1440px;
margin: auto;
margin-top: -160px;

}
.whyActionfi::before {
  content: ''; /* Create an empty pseudo-element */
  position: absolute;
  top: 0; /* Position it at the top of the container */
  left: 0;
  width: 100%;
  height: 100%; /* Make it cover the entire container */
  background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
  z-index: 1; /* Ensure it appears above the image */
}

.whyActionfi .headingSection {
  position: relative; /* Ensure it stays on top of background */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Horizontally center-align text */
  justify-content: center; /* Vertically center-align text if needed */
  margin:  auto; /* Center horizontally within the parent container */
  padding-top: 5rem; /* Adjust spacing above the content */
  padding-bottom: 9rem;
  text-align: center; /* Center-align the text content */
  width: 64%; /* Maintain the desired width */
  z-index: 2; /* Keep text above background or other layers */

}

.whyActionfi .headingSection >span{
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #394B99;
}

.whyActionfi .headingSection >button {
  margin-top: 20px; /* Space above button */
  padding: 10px 20px; /* Padding for button */
  font-family: Inter, sans-serif; /* Font style */
  color: #000000; /* Button text color */
  /* background-color: #394B99; Button background color */
  border: none; /* Remove default border */
  display: inline-flex; /* To align text and icon */
  align-items: center; /* Center-align content */
  gap: 10px; /* Spacing between text and icon */
  cursor: pointer; /* Pointer cursor on hover */
  background: none;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
  

.whyActionfi .headingSection .arrowIcon {
  color: #000000; /* Icon color */
  font-size: 35px; /* Icon size */
  transition: color 0.3s ease; /* Smooth hover effect */
}



.whyAfi_section2{
display: flex;
flex-direction: column;
align-items: left;
  padding: 64px;
  border-radius: 30px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  margin: 0px 120px;
  margin-top: -80px;
  position: relative;
  z-index: 20;
  background-color: #ffff;
}
.whyAfi_section3{


margin-top: 5rem;
}



.whyAfi_section2 >span{
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
   line-height: 56px;
  text-align: center; 
   color: #394B99;
   margin-bottom: 40px;
}
.whyActionfi .headingSection >p{
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  margin-top: 40px;
  color: #000000;
  
}
.whyAfi_section2 .imageWrapper >img{
  display: block; /* Ensure the image is displayed */
  max-width: 100%; /* Prevent image overflow */
  height: auto; /* Maintain aspect ratio */
  align-items: left;

}
.whyAfi_section2 .solutionImage{
  display: flex;
  justify-content: center;
  margin: 0rem 0px;
  align-items: left;
  
}
.whyAfi_section2 .customerGroup{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 1fr));
  gap: 3vh;
  margin: 30px 0px;
}
.CustomerGroup{
  display: flex;
  gap: 5vh;
}
.CustomerGroup .Container {
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-bottom: 20px;
}
.CustomerGroup .Container>h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}
.CustomerGroup .Container>p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.solutionContainer .imageWrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
}
.solutionContainer >p {
  text-align: center;
}


.caseStudiesBtn {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  background-color:transparent;
  z-index: 1;
 cursor: pointer;
}



/* .cardRight {
  flex: 1;

} */
.casebuttonRow {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-right: 120px;
  justify-content: flex-end; /* Aligns the button to the right */

  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  .bannerWidget  {
  
    background-position-x: 40%;
  }
  .bannerWidget .row {
    min-height: 200vh;
  }
}
@media only screen and (min-width: 992px) {
  .bannerWidget .powerup{
    display: none;
  }
  .bannerWidget .card p {
    max-height: 0;
    opacity: 0;
    overflow:hidden;
    -webkit-transition: max-height 1s ease-in-out;
    -moz-transition: max-height 1s ease-in-out;
    -ms-transition: max-height 1s ease-in-out;
    -o-transition: max-height 1s ease-in-out;
    transition: max-height 1s ease-in-out;
  }
  .bannerWidget .card:hover p {
    max-height: 50px;
    opacity: 100;
    overflow:hidden;
    -webkit-transition: max-height 1s ease-in-out,opacity 3s ease-in-out;
    -moz-transition: max-height 1s ease-in-out,opacity 3s ease-in-out;
    -ms-transition: max-height 1s ease-in-out,opacity 3s ease-in-out;
    -o-transition: max-height 1s ease-in-out,opacity 3s ease-in-out;
    transition: max-height 1s ease-in-out,opacity 3s ease-in-out;
  }

  .bannerWidget .row {
    min-height: 100vh;
  }
}
.bannerWidget  .automationLibrary {
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.bannerWidget .flexContainer {
  margin-top: -2%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 100%;
}
.bannerWidget .flexContainer .flex-child{
  margin-top: auto;
  margin-bottom: auto;
}
.bannerWidget .flexContainer h6{
  height: 100%;
  width: 100%;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5rem;

}



/* ------------------case study header arrow animation---------------------------- */

.scroll_prompt {
  margin-left: 15px;
  /* position: fixed;
  z-index: 998;

  left: 50%;
  margin-left: -80px;
  
  width: 160px;
  height: 160px; */

  .scroll_prompt_arrow_container {
    /* position: absolute;
    top: 0;
    left: 50%;
    margin-left: -18px; */
    margin-left: 15px;
    display: flex;
    animation-name: bounce;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .scroll_prompt_arrow {
    animation-name: opacity;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .scroll_prompt_arrow:last-child {
    animation-direction:alternate-reverse;
 
  }
  .scroll_prompt_arrow > div {
    width: 36px;
    height: 36px;
    border-right: 8px solid #bebebe;
    border-bottom: 8px solid #bebebe;
    transform: rotate(45deg) translateZ(1px);
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0.9;
  }

  20% {
    opacity: 0.8;
  }

  30% {
    opacity: 0.7;
  }

  40% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.4;
  }

  70% {
    opacity: 0.3;
  }

  80% {
    opacity: 0.2;
  }

  90% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(-3px);
  }

  20% {
    transform: translateX(-6px);
  }

  30% {
    transform: translateX(-9px);
  }

  40% {
    transform: translateX(-12px);
  }

  50% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-18px);
  }

  70% {
    transform: translateX(-21px);
  }

  80% {
    transform: translateX(-24px);
  }

  90% {
    transform: translateX(-27px);
  }

  100% {
    transform: translateX(-30px);
  }
}

.flexChild{
  margin-top: 17px;
  margin-right: 49px;
}
.chevronContainer {
  display: flex;
  justify-content: center;
  align-items: center;

   rotate: 270deg;

 }
 $base: 0.3rem;
 .chevron {
   position: absolute;
   width: $base * 3.5;
   height: $base * 0.8;
   opacity: 0;
   transform: scale(0.3);
   animation: move-chevron 3s ease-out infinite;
 }
 
 .chevron:first-child {
   animation: move-chevron 3s ease-out 1s infinite;
 }
 
 .chevron:nth-child(2) {
   animation: move-chevron 3s ease-out 2s infinite;
 }
 
 .chevron:before,
 .chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #94AFD4;
 }
 
 .chevron:before {
  left: 0;
  transform: skewY(30deg);
 }
 
 .chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
 }
 
 @keyframes move-chevron {
  25% {
   opacity: 1;
   }
  33.3% {
   opacity: 1;
   transform: translateY($base * 3.8);
  }
  66.6% {
   opacity: 1;
   transform: translateY($base * 5.2);
  }
  100% {
   opacity: 0;
   transform: translateY($base * 8) scale(0.5);
  }
 }
 .runMyBot video{
   width: 100%;
   height: auto;
 }
 @media (max-width:1200px){
  .CustomerGroup{
    display: flex;
    flex-direction: column;
  }
  .whyActionfi .headingSection{
    padding-bottom: 2rem;
    padding-top: 7rem;
  }
  // .CustomerGroup .Container .imageWrapper{
  //   margin: auto;
  // }
  .CustomerGroup .Container >h3 {
    text-align: left;
  }
  .CustomerGroup .Container >p {
    text-align: left;
  }

}
@media(max-width:800px){
  .whyAfi_section2{
    margin: -40px 30px 40px 30px;
  }
}
@media(max-width:600px){
  .whyAfi_section2{
    margin: -40px 30px 40px 30px;
  }
  .casebuttonRow{
    margin: 10px 30px;
  }
  .whyActionfi{
    padding: 120px 30px;
    width: auto;
  }
  .whyActionfi .headingSection{
    width: 100%;
    margin: auto;
    padding-top: 0 !important;
  }
  .whyAfi_section3{
    margin: 0px 30px;
  }
  // .CustomerGroup .Container .imageWrapper{
  //   margin: auto;
  // }
  .CustomerGroup .Container >h3 {
    text-align: left;
  }
  .CustomerGroup .Container >p {
    text-align: left;
  }

}

@media(max-width:400px){
  .whyAfi_section2{
    margin: -40px 10px 40px 10px;
    padding: 32px;
  }
}
