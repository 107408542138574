/* Main container */
.rmbsapMainconatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;



}

/* Content container */
.rmbsapMainconatiner .contentContainer {
  text-align: center;
  max-width: 800px;
  width: 100%;
  padding: 2rem;


}

/* Heading styles */
.rmbsapMainconatiner .contentContainer .heading {
  font-size: 40px;
  font-weight: bold;
  color: #394B99;
  margin-bottom: 1rem;
}

/* Description styles */
.rmbsapMainconatiner .contentContainer .description {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */

}

/* Image styles */
.rmbsapMainconatiner .contentContainer .image {
  max-width: 100%;
  /* Make the image responsive */
  height: auto;
  margin-top: 1rem;
  object-fit: contain;
}




/* -------------------runmybot for sap section 2-------------------------- */
/* .rmbsapSection2{
    padding: 0px 120px;
  } */
/* -------------------runmybot for sap section 3-------------------------- */
.iframe_container {
  width: 100%;
}

.rmbsapSection3 {
  text-align: center;
  max-width: 1000px;
  width: 100%;

}

.rmbsapSection3 .heading {
  font-size: 32px;
  font-weight: bold;
  font-weight: 600;
  color: rgba(39, 39, 39, 1);
  position: relative;
  margin-bottom: 29px;
}

.rmbsapSection3 .heading::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* Offset by half the width to center */
  bottom: -24px;
  /* Adjust the space below the heading */
  width: 84px;
  /* Full width of the heading */
  height: 3px;
  /* Stroke height */
  background-color: #4F63BE;
  /* Stroke color */
}

.rmbsapSection3>p {
  color: #000;
  padding-top: 54px;
  padding-bottom: 46px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
}

/* -------------------runmybot for sap section 4-------------------------- */



/* ----------------features container--------------------- */
/* Features container    --------------         section--------*/
.sapfeaturesContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

}

/* Each feature section */
.featureSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5rem;
  padding: 160px 0px 160px;

}

.leftImage {
  flex-direction: row;
}

.rightImage {
  flex-direction: row-reverse;
}

/* Text content styles */
.textContent {
  flex: 1;
  min-width: 300px;
}

.featureLabel {
  color: #272727;
  font-kerning: none;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */

}

.featureLabel::after {
  content: '';
  display: block;
  width: 84px;
  height: 3px;
  background-color: rgba(79, 99, 190, 1);
  margin-top: 24px;
  animation: growUnderline 3s;
  /* Animation applied */
}

@keyframes growUnderline {
  0% {
    width: 0;
    /* Start with no underline */
  }

  100% {
    width: 80px;
    /* Grow to full width */
  }
}

.featureDescription {
  margin-top: 24px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
}

/* Image container styles */
/* Image container styles */
.imageContainer {
  flex: 1;
  display: flex;
  width: 100%;

}

.leftImage .imageContainer {
  justify-content: center;
  /* Align image to the left */
}

.rightImage .imageContainer {
  justify-content: center;
  /* Align image to the right */
}

.featureImage {
  min-width: 400px;
  /* Set maximum width for the image */
  height: auto;
  /* Maintain aspect ratio */
  width: 100%;
  object-fit: cover;
  /* Ensure image fits nicely */
}

/* --------------sap section 5-------------------- */
.rmbsapSection5 {
  margin-bottom: 160px;
}

/* -------------------sap section 6-----------------             */

.rmb_sapSection6 .heading {
  color: #394B99;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 140% */
  margin-top: 0px;

}




.headerImgMain {
  position: relative;
  width: 100%;
  /* Full width of the viewport */
  max-width: 800px;
  /* Set max width for layout consistency */
  margin: 0 auto;
  /* Center the container */
  height: auto;
  /* Let the height adjust automatically */
  overflow: hidden;
  /* Hide overflowing content */
}

@media (max-width:600px) {

  .iframe_vdo {
    width: -webkit-fill-available;
  }

  .featureImage {
    min-width: 200px;
  }
}

@media (max-width:1100px) {

  .iframe_vdo {
    width: -webkit-fill-available;
  }
}

@media(max-width:300px) {
  .textContent {
    min-width: 200px;
  }

}

@media(max-width:1000px) {
  .featureSection {
    padding: 80px 0px 80px;

  }

}