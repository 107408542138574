#outer-container {
  position: relative;
  width: 600px;
  /* Adjust as per your needs */
  height: 600px;
  /* Adjust as per your needs */
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_main {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: fadeLoop 10s infinite;
  /* 90 seconds (30 seconds per container * 3 containers) */
  opacity: 1;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_main.fade-in {
  opacity: 1;
  visibility: visible;
  /* transform: scale(1.2); */
}

.container_main.fade-out {
  opacity: 0;
  visibility: hidden;
  /* transform: scale(1.2); */
}

#container2 {
  width: 520px;
  height: 520px;
  border: 2.44px dashed #BDBCBD;
  ;
  display: grid;
  /* grid-template-columns: 30px;
    grid-template-rows: 30px; */
  place-content: center;
  border-radius: 50%;
  position: absolute;
  /* Position this circle in the center */
}

#container1 {
  width: 420px;
  height: 420px;
  border: 2.44px dashed #A6A6A6;
  display: grid;
  /* grid-template-columns: 30px;
    grid-template-rows: 30px; */
  place-content: center;
  border-radius: 50%;
  background-color: #F8FBFF;
  ;
  position: absolute;
  /* Position this circle inside the outer circle */
}

#container {
  width: 320px;
  height: 320px;
  border: 2.44px dashed #BDBBBD;
  display: grid;
  /* grid-template-columns: 30px;
    grid-template-rows: 30px; */
  place-content: center;
  background-color: #F8FBFF;
  ;
  border-radius: 50%;
  position: absolute;
  /* Position this circle inside the outer circle */
}

#container4 {
  width: 100px;
  height: 100px;

  display: grid;

  place-content: center;
  background-color: #F8FBFF;
  border-radius: 50%;
  position: absolute;
  /* Position this circle inside the outer circle */
}

.item {
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;

  animation: spin 45s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(167px) rotate(0);
}

.item {
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;


}

.item3 {
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;

  animation: spin2 45s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(218px) rotate(0);
}

.item2 {
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;

  animation: spin3 45s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(270px) rotate(0);
}

.item2.fade-in {
  opacity: 1;
  visibility: visible;
}

.item2.fade-out {
  opacity: 0;
  visibility: hidden;
}

.item3.fade-in {
  opacity: 1;
  visibility: visible;
}

.item3.fade-out {
  opacity: 0;
  visibility: hidden;
}

.item.fade-in {
  opacity: 1;
  visibility: visible;
}

.item.fade-out {
  opacity: 0;
  visibility: hidden;
}

.sub_container.fade-in {
  opacity: 1;
  visibility: visible;
}

.sub_container.fade-out {
  opacity: 0;
  visibility: hidden;
}

.item_img {
  width: 60px;
}

.item_img.fade-in {
  opacity: 1;
  visibility: visible;
}

.item_img.fade-out {
  opacity: 0;
  visibility: hidden;
}

@keyframes spin {
  100% {
    transform: rotate(-1turn) translate(167px) rotate(1turn);
  }
}

@keyframes spin2 {
  100% {
    transform: rotate(1turn) translate(218px) rotate(-1turn);
  }
}

@keyframes spin3 {
  100% {
    transform: rotate(-1turn) translate(270px) rotate(1turn);
  }
}

@media(max-width:800px) {
  #outer-container {
    width: 400px;
    height: 400px;
  }

  #container2 {
    width: 320px;
    height: 320px;

  }

  #container1 {
    width: 250px;
    height: 250px;

  }

  #container {
    width: 180px;
    height: 180px;
  }

  .item {
    animation: spinsm 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(93px) rotate(0);
  }

  .item3 {
    animation: spinsm3 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(125px) rotate(0);
  }

  .item2 {

    animation: spinsm2 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(162px) rotate(0);
  }

  .item_img {
    width: 37px;
  }

  .center_rmbimg {
    width: 100%;
    min-width: 100px;
  }

}

@keyframes spinsm {
  100% {
    transform: rotate(-1turn) translate(93px) rotate(1turn);
  }
}

@keyframes spinsm3 {
  100% {
    transform: rotate(1turn) translate(125px) rotate(-1turn);
  }
}

@keyframes spinsm2 {
  100% {
    transform: rotate(-1turn) translate(162px) rotate(1turn);
  }
}

@media(max-width:400px) {
  #outer-container {
    width: 360px;
    height: 360px;
  }

  #container4 {
    width: 60px;
    height: 70px;

  }

  #container2 {
    width: 270px;
    height: 270px;

  }

  #container1 {
    width: 200px;
    height: 200px;

  }

  #container {
    width: 110px;
    height: 110px;
  }

  .item {
    animation: spinxs 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(57px) rotate(0);
  }

  .item3 {
    animation: spinxs3 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(98px) rotate(0);
  }

  .item2 {

    animation: spinxs2 45s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(137px) rotate(0);
  }



  .item_img {
    width: 30px;
  }

  .center_rmbimg {
    width: 100%;
    min-width: 60px;
  }

}

@keyframes spinxs {
  100% {
    transform: rotate(-1turn) translate(57px) rotate(1turn);
  }
}

@keyframes spinxs3 {
  100% {
    transform: rotate(1turn) translate(98px) rotate(-1turn);
  }
}

@keyframes spinxs2 {
  100% {
    transform: rotate(-1turn) translate(137px) rotate(1turn);
  }
}