.eventproSection1{
    position: relative; /* To position the pseudo-element relative to the container */
    display: flex;

    border-radius: 0px 0px 40px 40px;
   background-image: url('../../Assets/Images/Eventpro/eventproback.svg');
    background-size: 100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    min-height: 600px;
    margin-top: -160px;
}


.eventproSection1 .headingSection {
    position: relative; /* To make sure the content appears above the background overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 2; /* Ensure text appears above the pseudo-element */
    padding: 0px 120px;
}
.headingSection > p{
    width: 40%;
      text-align: left;
      color: #000;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
}
.eventproSection1 .headingSection .imageContainer {
    display: flex;
    gap:30px;
    margin-bottom: 15px;
}
.eventproSection1 .headingSection >span{
    font-family: Inter;
    font-size: 33px;
font-weight: 700;
line-height: 56px;
text-align: center;
color: #394B99;
}


/* ----------------section 2------------------ */

.eventproSection2{
    padding: 0px 120px;
}
.eventproSection2 .heading{
    text-align: center;
    color: rgba(57, 75, 153, 1);
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
     line-height: 38.73px;
     margin: 0px;

}
.headingFeatures{
    text-align: center;
    color: rgba(57, 75, 153, 1);
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    padding-top: 80px;
    padding-bottom: 80px;
     line-height: 38.73px;
     margin: 0px;
 
}
/* 
-------------------eventprosection5 */
.eventproSection5{
    padding-top: 80px;
    padding-bottom: 80px;
}



/* ---------------------features secti-------------------- */

 /* Features container    --------------         section--------*/
 .featuresContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    margin-bottom: 85px;
   
  }
  
  /* Each feature section */
  .featureSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
   padding-top:46px ;
    padding-bottom: 0px;
   
  }
  
  .leftImage {
    flex-direction: row;
  }
  
  .rightImage {
    flex-direction: row-reverse;
  }
  
  /* Text content styles */
  .textContent {
    flex: 1;
  }
  
  .featureLabel {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: rgba(79, 99, 190, 1);
    position: relative;
    text-align: left;
  }
  
  .featureLabel::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: rgba(79, 99, 190, 1);
    margin-top: 0.5rem;
  }
  
  .featureDescription {
    color: #272727;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px
  }
  
  /* Image container styles */
  /* Image container styles */
  .imageContainer2 {
    flex: 1;
    display: flex;
  }
  
  .leftImage .imageContainer2 {
    justify-content: flex-end; /* Align image to the left */
  }
  
  .rightImage .imageContainer2 {
    justify-content: flex-start; /* Align image to the right */
  }
  
  .featureImage {
    max-width: 400px; /* Set maximum width for the image */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure image fits nicely */
  }


    .say_Hello {
      display: flex;
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
   padding-top: 24px;
      font-weight: 600;
      line-height: 38px; /* 190% */
    }
    .say_Hello .run {
      color: #000;
      font-family: "Red Hat Display";
      font-size: 20px;
      font-style: italic;
      font-weight: 700;
      line-height: 38px;
    }
  
    .say_Hello .my {
      color: #000;
      font-family: "Red Hat Display";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px;
    }
  
    .say_Hello .bot {
      color: #000;
      font-family: "Red Hat Display";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }

    @media screen and (max-width: 1000px) {

      .rightImage
      {
        flex-direction: column-reverse;
      }
      .eventprotriangle{
        max-width: 450px;
      }
      .eventproSection1{
        background-size: cover;
      }
      .headingSection > p{
        width: auto;
      }
    }
    @media screen and (max-width: 600px) {

     
      .eventprotriangle{
        max-width: 350px;
      }
     .eventproSection1 .headingSection{
        padding: 0px 30px;
      }
      .eventproSection2{
        padding: 0px 30px;
      }
      .headingSection >p{
        width: 100%;
      }
      .eventproSection1{
        background-size: cover;
      }
    
    }