/* Container for all cards */
.platformcardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  /* Individual card styling */
  .platformcard {
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
   
  }
  
  /* Card content container */
  .platformcardheading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    min-height: 180px;
  }
  
  /* Label styling */
  .descriptionItem .label {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .platformcardheading .Headinglabel{
    text-align: justify;
    font-weight: 600;
    font-size: 24px;
    padding:0px 25px;

  }
  /* Image container */
  .platformcardheading .imageContainer {
    flex-shrink: 0;
    height: 100%;
    display: flex   ;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
   
  }
  
  .imageContainer .image {
    height: 80px;
    width: 80px; 
    object-fit: cover;
   
  }
  
  /* Description section */
  .description {
    font-size: 14px;
    color: #6D6D6D;
    padding: 1rem 2rem;
  }
  
  .descriptionItem {
    margin-bottom: 16px;
  }
  
  .descriptionLabel {
    font-weight: bold;
    margin-bottom: 8px;
    display: inline;
  }
  
  .descriptionText {
    line-height: 1.5;
    display: inline;
  }
  

  @media (max-width: 900px) {
    .platformcardContainer{
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media (max-width: 570px) {
    .platformcardContainer{
     padding: 20px 0px;
    }
  }
  