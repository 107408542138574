/* Layout wrapper */
.layoutContainer {
  display: flex;
  flex-direction: column;


}

/* Header Section */
.header {
  /* width: 100%; */
  background-color: #f8f8f8;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Main Content Section */
.mainContent {
  flex-grow: 1;
  /* This ensures the content area grows to take available space */
  max-width: 1440px;
  margin: auto;
  padding: 160px 120px 0px 120px;
}

/* Footer Section */
.footer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  flex-wrap: wrap;
  /* max-width: 1440px; */
  margin: auto;
  padding-top: 80px;
  padding-bottom: 27px;
  text-align: center;
  background-color: #F6FAFFA1;
  gap: "25px";
}

.footerContainer {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 0px 120px;
  flex-wrap: wrap
}

.FooterBottomSection {
  /* max-width: 1440px; */
  /* margin: auto; */

  text-align: center;
  background-color: #FFFFFF;
  padding: 0px 120px;

}

.wave {
  width: 100%;
  height: 68px;
  background: #E9EFF7;
  position: relative;
  background-size: 200% 100%;
  clip-path: polygon(0 50%, 25% 60%, 50% 40%, 75% 60%, 100% 50%, 100% 100%, 0 100%);
  overflow: hidden;
  animation: wave-flow 4s linear infinite;
}

@keyframes wave-flow {
  0% {
    background-position: 0% 0%;
    clip-path: polygon(0 40%, 30% 50%, 50% 40%, 70% 50%, 90% 40%, 100% 50%, 100% 100%, 0 100%);
  }

  50% {
    background-position: 100% 0%;
    clip-path: polygon(0 50%, 30% 40%, 50% 50%, 70% 40%, 90% 50%, 100% 40%, 100% 100%, 0 100%);
  }

  100% {
    background-position: 0% 0%;
    clip-path: polygon(0 40%, 30% 50%, 50% 40%, 70% 50%, 90% 40%, 100% 50%, 100% 100%, 0 100%);
  }
}

/* @keyframes wave-flow {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
} */

@media (max-width:300px) {
  .footerContainer {
    padding: 0px;
  }

  .FooterBottomSection {
    padding: 0px;
  }

}

@media (max-width:600px) {
  .footer {
    padding: 80px 34px;
    /* padding-left: 40px; */
    /* justify-content: center; */
  }

  .mainContent {
    padding: 160px 30px 0px 30px;
  }

  .footerContainer {
    padding: 0px;
  }

  .FooterBottomSection {
    padding: 0px;
  }

}

@media (max-width:900px) {
  /* .footerContainer {
    padding: 0px;
  } */

  /* .FooterBottomSection {
    padding: 0px;
  } */

}


/* contact us */


.bottomSection {
  /* max-width: 1200px; */
  margin: auto;
  background-color: #FFF;

}

.bottomLine {
  border: 1px solid #d9d9d9;
  margin-top: 0px;
  margin: 0px 120px;

}

.footerlinksSection {
  display: flex;

  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 120px;
  justify-content: space-between;
}

.footerIcons .LogoWrapper {
  margin-right: 20px;
}

.footerBottomleft {
  display: flex;

  gap: 10vh;
  margin-right: 120px;
  padding: 30px 0px;
}

.LogoWrapper>a {
  color: transparent !important;
}

.iconWrap {
  margin: 8px 0px;
  text-decoration: none !important;
  color: inherit;
}

@media (max-width: 600px) {
  .contactusform {
    width: 100%;
  }

  .contactuscontainer .image {
    min-width: 200px;
    width: 100%;
  }

  .usAddress {
    padding: 30px;
  }

  .cochinAdress {
    padding-right: 0px;
    padding-left: 30px;
    padding-bottom: 30px;

  }

  .calicutAdress {
    width: auto;
    padding-left: 30px;
  }


  .footerlinksSection {
    justify-content: center;
  }

  .actionfibottom {
    justify-content: center;
  }

  .footerBottomleft {
    margin-left: 0px;
    gap: 4vh;
    flex-direction: column;
  }



  .footerlinksSection {
    padding: 30px;
  }

  .bottomLine {
    margin: 0px 30px;
  }
}

@media (min-width: 1400px) {
  .usAddress {
    padding: 30px 10px 10px 300px
  }
}