.pageContainer {

  /* margin: 2rem 150px; */

  text-align: center;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.mainHeading {
  font-size: 2.5rem;
  color: #4f63be;
  margin-bottom: 60px;
}

.paragraph {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
  /* text-transform: capitalize; */
}

.subHeading {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
}

.content {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
  /* text-transform: capitalize; */
}

@media (max-width:600px) {
  .pageContainer {
    margin: 2rem 30px;
  }

}