/* Container for the marquee */
.marquee_container {
  width: 100%;
  overflow: hidden; /* Hide overflowing images */
  white-space: nowrap;
 
  padding: 0.5rem 0;
  position: relative;
}

/* The scrolling marquee */
.marquee {
  display: flex; /* Use flex to align images inline */
  animation: marquee 10s linear infinite; /* Scroll animation */
  will-change: transform; /* Optimize performance */
  gap: 60px; /* Control space between images */
}

/* Keyframes for marquee scrolling */
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}


/* Styling for images inside the marquee */
.marquee .image {
  max-height: 35px; /* Set a consistent height */
  max-width: 100px;
  object-fit: contain; /* Ensure images maintain aspect ratio */
}
