     .automationinnerContainer {
       display: flex;

       flex-direction: column;
       align-items: center;
     }

     .headerLabel {
       color: rgba(57, 75, 153, 1);
       font-family: Inter;
       font-size: 40px;
       font-weight: 600;
       line-height: 48.41px;
       text-align: center;
       text-underline-position: from-font;
       text-decoration-skip-ink: none;
       margin-bottom: 30px;
       text-align: center;



     }

     /* Features container    --------------         section--------*/
     .featuresContainer {
       display: flex;
       flex-direction: column;
       gap: 3rem;
       width: 100%;

     }

     /* Each feature section */
     .featureSection {
       display: flex;
       align-items: center;
       flex-wrap: wrap;
       justify-content: space-between;
       gap: 5rem;
       padding: 2rem 0px;

     }

     .leftImage {
       flex-direction: row;
     }

     .rightImage {
       flex-direction: row-reverse;
     }

     /* Text content styles */
     .textContent {
       flex: 1;
     }

     .featureLabel {
       color: #272727;
       font-kerning: none;
       font-family: Inter;
       font-size: 24px;
       font-style: normal;
       font-weight: 600;
       line-height: 32px;
       /* 133.333% */
     }

     .featureLabel::after {
       content: '';
       display: block;
       width: 50px;
       height: 3px;
       background-color: rgba(79, 99, 190, 1);
       margin-top: 0.5rem;
     }

     .featureDescription {
       color: #000;
       font-family: Inter;
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 32px;
       min-width: 300px;
       /* 200% */
     }

     .textContent .featureDescription>p {
       font-family: Inter;
       font-size: 16px;
       font-weight: 400;
       line-height: 32px;
       text-align: left;
       text-underline-position: from-font;
       text-decoration-skip-ink: none;

     }

     /* Image container styles */
     /* Image container styles */
     .imageContainer {
       flex: 1;
       display: flex;
     }

     .leftImage .imageContainer {
       justify-content: center;
       /* Align image to the left */
     }

     .rightImage .imageContainer {
       justify-content: center;
       /* Align image to the right */
     }

     .featureImage {
       min-width: 400px;
       width: 100%;
       /* Set maximum width for the image */
       height: auto;
       /* Maintain aspect ratio */
       object-fit: cover;
       /* Ensure image fits nicely */
     }


     .noDataFound {
       color: #000;
       text-align: center;
       font-family: Inter;
       font-size: 16px;
       display: flex;
       justify-content: center;
       font-style: normal;
       font-weight: 400;
       width: 100%;
       line-height: normal;
       padding: 5rem 20px;
     }

     .loading_div {
       color: #000;
       text-align: center;
       font-family: Inter;
       font-size: 16px;
       display: flex;
       justify-content: center;
       font-style: normal;
       font-weight: 400;
       width: 100%;
       line-height: normal;
       padding: 5rem 20px;
     }

     .loadMore {
       cursor: pointer;
       font-family: Inter;
       font-size: 16px;
       font-weight: 600;
       line-height: 19.36px;
     }

     .automation_backbtn>button {
       color: #ffffff;
       padding: 12px 24px;
       margin-bottom: 5rem;
       margin-top: 2rem;
       font-size: 16px;
       background-color: #4F63BE;
       ;
       border: none;
       border-radius: 32px;
       cursor: pointer;
       transition: background-color 0.3s ease;
     }

     .automation_backbtn {
       display: flex;
       width: 100%;
       align-items: flex-end;
       justify-content: flex-end;
     }

     .automation_backbtn>button:hover {
       background-color: #4040c5;
     }

     @media(max-width:600px) {
       .automationinnerContainer {
         padding: 20px 0px;
       }

       .featureImage {
         min-width: 200px;

       }

     }

     @media(max-width:300px) {
       .featureDescription {
         min-width: 200px;
         /* 200% */
       }

     }