.pageContainer {



  text-align: center;

  line-height: 1.6;
}

.mainHeading {
  font-size: 2.5rem;
  color: #4f63be;
  margin-bottom: 60px;
}

.paragraph {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
  /* text-transform: capitalize; */
}

.subHeading {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
}

.content {
  font-size: 1rem;
  color: #000;
  text-align: left;
}

.link {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;

}
.bold-large-text {
  font-weight: bold;
  font-size: "1.2rem"; 
}
@media (max-width:600px) {
  .mainHeading {
    margin-bottom: 30px;
  }

  /* .pageContainer{
      margin: 0px 30px;
    } */

}