/* Parent Container */
/* .homeContainerMain{
  margin: 0px 120px 0px;

} */
.homePage {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: auto auto;
  /* Center the container horizontally */

}

/* Text Section */
.homePage .textSection {

  max-width: 600px;
  /* Limit the width of the text */
}

.homePage .textSection h1 {
  font-family: 'Inter';
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  padding-bottom: 48px;
  text-align: left;
  text-underline-position: 'from-font';
  text-decoration-skip-ink: none;
  color: #272727;

}

.homePage .textSection .moving_text {
  color: #848484;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 80px;
}

/* Image Section */
.homePage .imageSection {
  flex: 1;
  /* Takes up the remaining 50% */
  text-align: right;
  /* Align the image to the right */
  place-items: center;
  display: flex;
  justify-content: center;
  /* Center image horizontally */
  align-items: center;
  /* Center image vertically */
  margin-top: 20px;
}

.image {
  max-width: 100%;
  /* Ensures the image is responsive */
  height: auto;
  /* Keeps the image's aspect ratio intact */
  display: block;
  /* Ensures the image is displayed as a block element */
}

.homePage .imageSection .image {
  max-width: 100%;
  /* Ensure the image doesn't overflow */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 8px;
  /* Optional: Add rounded corners */
}

.homePage .textSection .ExpRmbButton {
  background: rgba(79, 99, 190, 1);
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 24px;
  max-height: 60px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 15px 15px;
  color: #fff;
  margin: auto;
  border-radius: 128px;
  width: 263px;
  height: 60px;
  border: none;
  cursor: pointer;

}

.ExpRmbButton .run {
  color: #FFF;
  font-family: "Red Hat Display";
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
}

.ExpRmbButton .my {
  color: #FFF;
  font-family: "Red Hat Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ExpRmbButton .bot {
  color: #FFF;
  font-family: "Red Hat Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nonAnimation {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #848484
}

.ovalBorder {
  display: inline-block;
  padding: 5px 15px;
  /* Adds space around the word */
  border: 1px solid #272721;
  /* Border color */
  border-radius: 30px;
  /* Makes it oval-shaped */
  /* background-color: #f9f9f9; Optional: Adds background color */
  /* font-weight: bold; Optional: Makes the text bold */
  color: #848484;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  transition: opacity 0.5s ease-in-out;
  /* Smooth fade effect */
  margin: 10px 0px;
}

.text {
  display: inline-block;
  transition: opacity 0.3s ease-in-out;
  /* Smooth fade effect */
}

.fadeIn {
  opacity: 1;
  transform: scale(1);
}

.fadeOut {
  opacity: 0;
  transform: scale(0.9);
  /* Slightly shrink during fade-out */
}

.automationRunsat {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-align: center;
  /* Center-align text */
}

.automationRunsat>p {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 64px;

}

.whatwegoodat {
  margin-top: 160px;
}

.whatwegoodat .imageSection {
  margin: 64px auto 0px;
}

.whatwegoodat>p {
  margin-top: 0px !important;
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  flex-direction: column;
  /* Align content vertically */
  align-items: center;
  /* Center content horizontally */
  text-align: center;
  /* Ensure text is centered */
}

.whatwegoodat>div {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* Align content vertically */
  align-items: center;
  /* Center content horizontally */
  text-align: center;
  /* Ensure text is centered */
  margin-bottom: 30px;

}

.testimonialContainer {
  margin-top: 150px;

}

.testimonialContainer>p {
  margin-top: 20px;
  margin-bottom: 80px;
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.morethanplatform {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 120px;
  margin-bottom: 4rem;
  border-radius: 8px;
}

.morethanplatform>p {
  margin-top: 0px;
  margin-bottom: 96px;
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* why actionfi */

.whyactionfi {
  padding-top: 125px;
  padding-left: 120px;
  padding-right: 120px;


}

.whyactionfi>h1 {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.whyactionfi>p {
  color: #6d6d6d;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 40px;
  margin-bottom: 72px;

}

.whyactionfi .explorefurther {
  display: flex;
  align-items: center;
  margin-top: 4rem;

}

.whyactionfi .explorefurther>button {
  background: linear-gradient(90deg, #7398C7 0%, #394B99 100%);
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 20px 20px;
  color: #fff;
  margin: auto;
  border-radius: 128px;
  width: 170px;
  border: none;

}

.ourPlatform {
  padding: 0px 50px;
  text-align: center;
  margin-top: 160px;
}

.ourPlatform video {
  display: block;
  margin: 0 auto;
}

.ourPlatform>h1 {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  margin-bottom: 56px;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.ourPlatform>p {
  color: #6d6d6d;
  font-family: Inter;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.sliders_wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;

}


/* Responsive Design */
@media (max-width: 990px) {
  .homePage {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .homePage {
    flex-direction: column;
    /* Stack text and image vertically */
    text-align: center;
    /* Center the content */
  }

  .homePage .textSection {
    margin-bottom: 20px;
    /* Add spacing between text and image */
  }

  .homePage .imageSection {
    text-align: center;
  }

  .homePage {
    margin-top: auto;
  }
}

@media (max-width:600px) {
  /* .homeContainerMain {
    margin: 0px 30px;
  } */

  .ourPlatform {
    padding: 0px;
  }

  .morethanplatform {
    padding: 0px 30px;
  }

  .homePage {
    margin-top: auto;
  }
}

.wave {
  width: 100%;
  height: 68px;
  background: #E9EFF7;
  position: relative;
  background-size: 200% 100%;
  clip-path: polygon(0 40%, 30% 50%, 50% 40%, 70% 50%, 90% 40%, 100% 50%, 100% 100%, 0 100%);
  overflow: hidden;
  animation: wave-flow 4s linear infinite;
}

@keyframes wave-flow {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.whyactionfi {
  padding-left: 0px;
  padding-right: 0px;
}

.text_container {
  display: inline-flex;
  flex-direction: column;
  height: 2.5rem;
  overflow: hidden;
  height: 45px;
  border-radius: 40px;
  border: 1px solid #272727;
  box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.10);
  text-align: center;
}

.text_container span {
  height: 29.5px;
  padding: 10px;
  color: #848484;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: transparent;
  animation: moveUp 15s linear infinite;

}

/* @keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  
  5% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(-50px);
  }

  25% {
    transform: translateY(-50px);
  }

  35% {
    transform: translateY(-100px);
  }

  45% {
    transform: translateY(-100px);
  }

  55% {
    transform: translateY(-150px);
  }

  65% {
    transform: translateY(-150px);
  }

  75% {
    transform: translateY(-200px);
  }

  95% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
} */