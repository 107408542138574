/* src/components/Awards.module.css */

.awardContainer {
  display: flex;
  justify-content: center;
  /* height: 350px; */
  overflow: hidden;
  width: 100%;
  margin: auto;
  margin-top: 5rem;
}

.awardCarousel {
  display: flex;
  width: 90%;
  overflow: hidden;
  gap: 20px
}

.carouselItem {
  flex: 1 0;
  background-image: url("../../Assets/Images/Companies/award_bagkground.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease-in-out;
  border-radius: 24px;
  height: 320px;
}

.carouselImage {
  width: 70%;
  max-width: 280px;
  max-height: 100%;
  margin: auto;
  border-radius: 10px;
}


/* 
@media(max-width:800px) {

  .awardCarousel {
    max-width: 600px;
    height: 200px;
  }
} */


@media(max-width:600px) {
  .awardContainer {
    height: 700px;
    width: 360px;
  }

  .awardContainer {
    margin: unset;
  }
}

@media(max-width:900px) {
  .awardContainer {
    height: 900px;
    width: 400px;
  }

  .awardCarousel {
    flex-direction: column;
  }

  .carouselImage {
    max-width: 220px;
  }

  .carouselItem {
    background-size: unset;
  }
}

@media (min-width: 900px) and (max-width: 1240px) {
  .carouselItem {
    height: 200px;
  }
}

@media (min-width: 900px) and (max-width: 1140px) {
  .awardCarousel {
    height: 260px;
    width: 976px;
  }
}

@media (min-width: 1860px) {
  .awardCarousel {
    width: 80%;
  }
}


/* 
h1 {
  text-align: center;
  margin: 1.5em;
}

img {
  width: 35%;
  border-radius: 50%;
}

.container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.items {
  display: flex;
  align-items: center;
  width: fit-content;
  animation: carouselAnim 50s infinite alternate linear;
}

.entry {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 300px;
  background: #fff;
  margin: 1em;
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.5);
} */

/* @media only screen and (max-width: 768px) {
  .items {
    animation: carouselAnim 35s infinite alternate linear;
  }
  
  @keyframes carouselAnim {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(calc(-100% + (2 * 300px)));
    }
  }
}

.entry p {
  text-align: center;
  padding: 1em;
}

.name {
  font-weight: bold;
}

@keyframes carouselAnim {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(-100% + (5 * 300px)));
  }
}

.container .faders {
  width: 100%;
  position: absolute;
  height: 100%;
}

.faders .right,
.faders .left {
  background: linear-gradient(
    to right,
    rgba(245, 245, 245, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 15%;
  height: 100%;
  position: absolute;
  z-index: 200;
}

.right {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(245, 245, 245, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
} */