/* Main container */
.IntegrationtileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 0px 0px 160px;


}

.integrationHeaderimage {
  width: 100%;
}

/* Content container */
.IntegrationtileContainer .contentContainer {
  text-align: justify;
  max-width: 800px;
  width: 100%;
  padding-bottom: 2rem;


}

/* Heading styles */
.IntegrationtileContainer .contentContainer .heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #394B99;
  margin-bottom: 40px;
  margin-top: 60px;
}

/* Description styles */
.IntegrationtileContainer .contentContainer .description {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-bottom: 160px;
}

/* Description styles */
.IntegrationtileContainer .contentContainer .description2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-bottom: 40px;
}

/* Image styles */
.IntegrationtileContainer .contentContainer .image {
  max-width: 100%;
  /* Make the image responsive */
  height: auto;
  margin-top: 1rem;
  object-fit: contain;
}


/* 
  -------------------------------card--------------------------- */
.integrationgrid {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
  transition: transform 0.5s ease-in;
  width: 100%;
  align-items: start;
  grid-auto-flow: row dense;
}



/* Box Container for Card */


/* Card Rectangle */
.rectangle {
  background-color: #ffffff;
  border: 2px solid #394b991a;
  border-radius: 14px;
  /* min-height: 150px;
  height: auto; */
  height: 320px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  box-shadow: 0px 0px 8px 0px #00000026;
  overflow: hidden;
  position: relative;
}

.rectangle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: #7398C7;
  /* transition: background-color 0.3s ease-in-out; */
}

.rectangle:hover::after {
  background-color: #E27DDD;

}

/* .rectangle:hover {
  border-bottom: 8px solid #E27DDD;
} */

.heading2 {
  margin-bottom: 40px;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

/* Image Style */
.image {
  width: auto;
  max-width: 120px;
  max-height: 40px;
  object-fit: cover;
  margin-bottom: 20px;

}

/* Label Style */
.label {
  min-height: 35px;
  width: 100%;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 16px;
  line-height: 1;
}

.label .textWrapper {
  color: #000000;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.detail .textWrapper {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 10px;

}

/* Description Style */
.description {
  margin-top: 8px;
  width: 100%;
  text-align: justify;

}

.description .textWrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  /* height: 80px; */
  height: 100px;
  word-wrap: break-word;
  /* Forces text to wrap */
  overflow: hidden;
  /* Hides any text that overflows */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) for overflowing text */
  white-space: normal;
  /* Ensures text wraps to a new line */
}

.description .textWrapper.expanded {
  max-height: 100px;
  overflow: auto;
  padding-right: 10px;
}

/* Hide scrollbar by default */
.description .textWrapper::-webkit-scrollbar {
  width: 6px;
  /* Thin scrollbar */
  opacity: 0;
  /* Invisible by default */
  transition: opacity 0.3s ease-in-out;
  /* Smooth transition */
}

.description .textWrapper::-webkit-scrollbar-track {
  background: transparent;
  padding: 5 px;
  /* Transparent track */
}

.description .textWrapper::-webkit-scrollbar-thumb {
  background-color: #DFDFDF;
}

.noDataFound {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
  padding: 5rem 20px;
}

.readMore {
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;
  margin-top: 5px;
}

.loading_div {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  padding: 5rem 20px;
}

@media(max-width:600px) {
  .IntegrationtileContainer {
    margin: 0px 30px 160px;
  }

  .IntegrationtileContainer .contentContainer .description {
    margin-bottom: 90px;
  }

  .integrationgrid {

    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  }

  .label {
    height: 50px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
}