.dropdownContainer {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(4, minmax(200px, 1fr)); */
  /* Automatically fills columns, 250px min */
  /* gap: 16px; */
  /* Spacing between items */
  padding: 10px 0px;
  background-color: #fff;
  /* Background color */
  border-radius: 8px;
  /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.section {
  flex: 1;
  /* padding: 0px 10px; */
}

.section>p {
  color: #272727;
  padding: 10px 20px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}

.section>ul {

  padding: 0;
}

.section :hover {
  /* border-radius: 8px; */
  background: rgba(200, 215, 234, 0.35);
}

.section>ul {
  list-style: none;
  color: #272727;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section>ul>li {
  padding: 10px 4px;
  cursor: pointer;

}

.section ul li a {
  text-decoration: none;
  color: inherit;
}

@media(max-width:1120px) {
  .dropdownContainer {
    display: flex;
    padding: 0px;
    flex-direction: column;
    gap: 0px;
    margin-top: 10px;
    margin-right: 20px;
    /* gap: 16px; */
    border: none;
    /* padding: 20px 150px; */
    box-shadow: none;
    border: none;
    background-color: #fff;

  }

  .section {
    padding: 0px !important;
  }

  .section>h3 {
    padding: 0px;
  }
}

.viewAllbuttton {
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0 0 0px;
  padding: 0 10px 0 0;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-end;

}

.viewAllbuttton:hover {
  background-color: transparent;
}

/* .viewAllbuttton::before{
    background: #111010;
    bottom: 0;
    content: "";
    height: 1px;
    position: absolute;
    width: 25%;
  }
  .uparrow :hover{
    content: "";
    display: inline-block;
    
    left: 10px;
    position: relative;
    transform: translate(0);
    transition: .3s;
    
  } */