.rmb_svgContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* Responsive width */
  height: 450px;
  /* Flexible height */
  margin-top: 73px;
}

.image {
  display: block;
  max-width: 60%;
  /* Scale image size dynamically */
  height: auto;
  /* Maintain aspect ratio */
  z-index: 1;
  /* Keep image above other elements */
}

/* Large screens */
.topLeftSvg,
.bottomRightSvg {
  position: absolute;
  z-index: 0;
  /* Send SVGs behind the image */
}

.topLeftSvg {
  top: -1%;
  left: 7%;
  transform: translate(85%, 3%);
}

.bottomRightSvg {
  bottom: 3%;
  right: 6%;
  transform: translate(-32%, -27%);

}

.svgCirclePink {
  position: absolute;
  left: 8%;
  top: 35%;
  animation: moveDiagonallyDownsmall 6s ease-in-out infinite;
  /* 3-second cycle, infinite loop */
}

.svgCirclePinkSmall {
  position: absolute;
  left: 7%;
  top: 16%;
  animation: moveDiagonallyupsmall 6s ease-in-out infinite;
  /* 3-second cycle, infinite loop */
}

.svgcircleRightSmall {
  position: absolute;
  right: 6%;
  bottom: 35%;
  animation: moveDiagonallyupright 6s ease-in-out infinite;
  /* 3-second cycle, infinite loop */
}

.svgcircleRight {
  position: absolute;
  right: 3%;
  bottom: 20%;
  animation: moveDiagonallyDownleft 6s ease-in-out infinite;
}

/* Top SVG moves diagonally downward */
.topLeftSvg {
  animation: moveDiagonallyDown 7s ease-in-out infinite;
  /* 3-second cycle, infinite loop */
}


@keyframes moveDiagonallyDown {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(30px, 30px);
    /* Move diagonally down-right */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally down-right */
  }
}

/* Bottom SVG moves diagonally upward */
.bottomRightSvg {
  animation: moveDiagonallyUp 7s ease-in-out infinite;
  /* 3-second cycle, infinite loop */
}

@keyframes moveDiagonallyUp {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(-30px, -30px);
    /* Move diagonally up-left */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally up-left */
  }

}

@keyframes moveDiagonallyDownsmall {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(50px, 50px);
    /* Move diagonally up-left */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally up-left */
  }

}

@keyframes moveDiagonallyDownleft {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(-50px, 50px);
    /* Move diagonally up-left */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally up-left */
  }

}

@keyframes moveDiagonallyupsmall {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(50px, -50px);
    /* Move diagonally up-left */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally up-left */
  }

}

@keyframes moveDiagonallyupright {
  0% {
    transform: translate(0, 0);
    /* Original position */
  }

  50% {
    transform: translate(-50px, -50px);
    /* Move diagonally up-left */
  }

  100% {
    transform: translate(0px, 0px);
    /* Move diagonally up-left */
  }

}


/* Smaller screens */
@media (max-width: 1000px) {
  .topLeftSvg {
    transform: translate(-10%, -10%);
    width: 150px;
    height: 150px;
  }

  .bottomRightSvg {
    transform: translate(10%, 10%);
    width: 150px;
    height: 150px;
  }

  .svgCirclePink {
    left: 5%;
    top: 30%;
    width: 12px;
    height: 12px;
  }

  .svgcircleRight {
    right: 5%;
    bottom: 15%;
    width: 12px;
    height: 12px;
  }

  .image {
    max-width: 70%;
    /* Make the image smaller for narrow screens */
  }
}

/* Smallest screens */
@media (max-width: 600px) {

  .topLeftSvg,
  .bottomRightSvg {
    width: 100px;
    height: 100px;
    transform: none;
    /* Reset transform */
  }

  .image {
    max-width: 60%;
    /* Full width for the smallest screens */
  }

  .rmb_svgContainer {
    height: 300px;
  }

  .topLeftSvg {
    top: 8%;
    left: 9%;
  }
}