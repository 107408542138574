/* Main container styles */
.companyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0px 120px 160px ; */
  text-align: center;
  overflow: hidden;
}

/* Heading styles */
.companyHeading {
  color: #394B99;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  margin: 0px 5rem 48px;
  line-height: 56px;
  /* 140% */
}

/* Main image container */
.imageContainerMain {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  border-radius: 10px;

  margin-bottom: 5rem;
}

.companyImage {
  width: 100%;
  height: auto;
  display: block;
}

/* Features container */
.featuresContainerMain {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;

}

.CompanyPagePartners {
  overflow: hidden;
  margin-top: 160px;

}

.CompanyPagePartners>h1 {
  color: #394B99;

  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  margin-bottom: 64px;

  font-weight: 600;
  line-height: normal;
}

.experience {
  margin-top: 116px;
  margin-bottom: 160px;

}

.experience>h1 {
  padding-bottom: 40px;
  color: #394B99;

  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.experience>p {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 50px;

}

.experience .explorefurther {
  display: flex;
  align-items: center;

}

.experience .explorefurther>button {
  background: rgba(79, 99, 190, 1);
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 24px;
  max-height: 60px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 15px 15px;
  color: #fff;
  margin: auto;
  border-radius: 128px;
  width: 263px;
  height: 60px;
  border: none;
  cursor: pointer;

}


/* ---------------features section--------------- */
.featuresContainerMain {
  margin-top: 3rem;
}

/* Features container    --------------         section--------*/
.featuresContainerCompany {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

}

/* Each feature section */
.featureSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  flex-wrap: wrap;
  padding: 2rem 0px 0px;

}

.leftImage {
  flex-direction: row;
}

.rightImage {
  flex-direction: row-reverse;
}

/* Text content styles */
.textContent {
  /* flex: 1; */
  width: 45%
}

.featureLabel {
  color: #272727;
  font-kerning: none;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  /* 133.333% */
}

.featureLabel::after {
  content: '';
  display: block;
  width: 84px;
  height: 3px;
  background-color: rgba(79, 99, 190, 1);
  margin-top: 24px;
  animation: growUnderline 3s;
  /* Animation applied */
}

@keyframes growUnderline {
  0% {
    width: 0;
    /* Start with no underline */
  }

  100% {
    width: 80px;
    /* Grow to full width */
  }
}

.featureDescription {
  font-size: 1rem;
  color: #000;
  text-align: left;
  margin-top: 24px;
  line-height: 32px;
}

/* Image container styles */
/* Image container styles */
.imageContainer {
  flex: 1;
  display: flex;
  width: 45%
}

.leftImage .imageContainer {
  justify-content: flex-end;
  /* Align image to the left */
}

.rightImage .imageContainer {
  justify-content: flex-start;
  /* Align image to the right */
}

.featureImage {
  max-width: 450px;
  /* Set maximum width for the image */
  height: auto;
  width: 100%;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure image fits nicely */
}

.automationRunsat {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-align: center;
  /* Center-align text */
}

.automationRunsat>p {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 64px;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

@media (max-width:1038px) {
  .textContent {

    width: auto;
  }

  .leftImage .imageContainer {
    justify-content: center;
  }

  .rightImage .imageContainer {
    justify-content: center;
  }

  .imageContainer {
    width: auto;
  }
}

@media(max-width:600px) {

  /* .companyContainer{
    margin: 2rem 30px 160px;
  } */
  .companyHeading {
    margin-right: 0px;
    margin-left: 0px;
  }

  .automationRunsat {
    width: 100%;
  }

}