        /* Features container    --------------         section--------*/
        .featuresContainer {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            margin-top: -20px;
            /* margin: 0px 120px 160px; */


        }

        /* Each feature section */
        .featureSection {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 4rem;
            padding: 2rem 0px;


        }

        .leftImage {
            flex-direction: row;
        }

        .rightImage {
            flex-direction: row-reverse;
        }

        /* Text content styles */
        .textContent {
            flex: 1;
        }

        .featureLabel {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 24px;
            color: rgba(79, 99, 190, 1);
            position: relative;
            text-align: left;
        }

        .labelImages {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

        }

        .labelImages .labelImage {
            margin-right: 10px;
        }

        /* Ensure the inner content scrolls only */
        .featureDescriptionContainer {
            max-height: 300px;
            /* Adjust this value as needed */
            overflow-y: auto;
            /* Enable vertical scrolling */
        }

        /* Optional: Make sure the rest of the page scrolls normally */
        .featuresContainer {
            overflow: auto;
            /* This will let the main page scroll naturally */
        }

        .scrollable {
            max-height: 300px;
            /* Set this to the desired height */
            overflow-y: auto;
            touch-action: pan-y;
        }

        /* .featureLabel::after {
            content: '';
            display: block;
            width: 50px;
            height: 3px;
            background-color: rgba(79, 99, 190, 1);
            margin-top: 0.5rem;
          } */

        .featureDescription {
            font-size: 16px;
            color: #000000;
            text-align: left;
            line-height: 28px;
            /* 175% */
        }

        /* Image container styles */
        /* Image container styles */
        .imageContainer {
            flex: 1;
            display: flex;
        }

        .leftImage .imageContainer {
            justify-content: flex-end;
            /* Align image to the left */
        }

        .rightImage .imageContainer {
            justify-content: flex-start;
            /* Align image to the right */
        }

        .featureImage {
            max-width: 400px;
            /* Set maximum width for the image */
            height: auto;
            min-width: 200px;
            /* Maintain aspect ratio */
            object-fit: cover;
            /* Ensure image fits nicely */
        }

        .featureSubLabel {
            color: #404040;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 8px;
            line-height: normal;
        }

        @media(max-width:1132px) {
            .leftImage .imageContainer {
                justify-content: center;
                /* Align image to the left */
            }

            .rightImage .imageContainer {
                justify-content: center;
                /* Align image to the right */
            }

            .rightImage {
                flex-direction: column;
            }
        }

        @media(max-width:600px) {
            .featuresContainer {
                margin: 0px 30px;
            }

            .featureSection {
                padding: 1rem 0px;
            }

        }

        @media(max-width:400px) {
            .labelImages .labelImage {
                margin-top: 10px;
            }

        }