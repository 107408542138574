/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* index.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/astonpoliz');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
/* Apply Inter font globally */
body {
  font-family: 'Inter', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #272727;

  /* background: linear-gradient(
    to right,
    #d4d6f4, 
    rgba(255, 255, 255, 1) 40%, 
    rgba(255, 255, 255, 1) 60%, 
    #f4d7f5 
  );
  backdrop-filter: blur(314px);  */
}
h1{
  color: #394B99;
  font-family: "Inter-SemiBold", Helvetica;
  margin: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  margin: 0px;
}