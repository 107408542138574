/* Wrapper Grid to make it responsive and align horizontally */
.grid {

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  transition: transform 0.5s ease-in;
}

/* Box Container for Card */
.box {
  cursor: pointer;
}

/* Card Rectangle */
.rectangle {
  background-color: #ffffff;
  border: 2px solid #394b991a;
  border-radius: 24px;
  min-height: 120px;
  height: 100%;
  /* justify-content: space-around; */
  /* width: 282px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Image Style */
.image {
  max-width: 100px;
  width: 100%;
  object-fit: cover;

}

/* Label Style */
.label {
  height: 38px;
  width: 100%;
  text-align: left;
  margin-top: 8px;
}

.label .textWrapper {
  color: #394b99;
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.img_container {
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: flex-start;
  min-height: 87px;
}

/* Description Style */
.description {
  margin-top: 8px;
  width: 100%;
  text-align: left;
}

.description .textWrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 850px) and (max-width: 1240px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .rectangle {
    height: auto;
  }
}

@media(max-width:850px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .rectangle {
    height: auto;
  }
}