

.rc-carousel {
    --box-gap: 1.5rem;
    --tile-size: 16rem;
    width: 100%;        
    max-width: calc(
      (var(--tile-size) + var(--box-gap)) * ((var(--tiles) / 2) - 1)
    );

    overflow: hidden;
    position: relative;
    z-index: 10;
  }
  .rc-carousel::before,
  .rc-carousel::after {
    --body-bg: white;
    content: "";
    position: absolute;
    top: 0;
    width: 4rem;
    height: 100%;
    background-image: -webkit-gradient(
      linear,
      from(transparent),
      to(var(--body-bg))
    );
    background-image: -o-linear-gradient(
      var(--direction),
      transparent,
      var(--body-bg)
    );
    background-image: linear-gradient(
      to var(--direction),
      transparent,
      var(--body-bg)
    );
    z-index: inherit;
  }
  .rc-carousel::before {
    left: 0;
    --direction: left;
  }
  
  .rc-carousel::after {
    right: 0;
    --direction: right;
  }
  .rc-carousel-strip {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 35px;
    -webkit-animation: slideX 20s linear infinite;
    animation: slideX 20s linear infinite;
  }
  .rc-carousel-strip.reverse {
    /* reverse animation */
    animation-direction: reverse;
  }
  .rc-carousel-strip:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .rc-carousel-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: var(--box-gap);
  }
  
  .rc-carousel-item {
    /* width: 282px; */
    height: 74px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #ffffff;

    align-items: center;
    justify-content: center;
    border-radius: 10px;
    display: flex;
   
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

     width: var(--tile-size);
    /* height: var(--tile-size);  */
  }
  
  .rc-carousel-item-image {
    max-width: 130px;
    max-height: 45px;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
  }
  
  @-webkit-keyframes slideX {
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    0% {
      -webkit-transform: translateX(
        calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
      );
      transform: translateX(
        calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
      );
    }
  }
  
  @keyframes slideX {
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    0% {
      -webkit-transform: translateX(
        calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
      );
      transform: translateX(
        calc(-1 * (var(--tile-size) + var(--box-gap)) * (var(--tiles) / 2))
      );
    }
  }
  
  /* Animation won't work if you preferes reduced motion */
  @media (prefers-reduced-motion) {
    /* styles to apply if a user's device settings are set to reduced motion */
    .rc-carousel::before,
    .rc-carousel::after {
      display: none;
    }
    .rc-carousel-box {
      flex-wrap: wrap;
      justify-content: center;
    }
    .rc-carousel-box [aria-hidden="true"] {
      display: none;
    }
    .rc-carousel-strip {
      animation: none;
    }
  }
  /* reduced motion css ends */
  /* Carousel and animation css ends */
  
  /* Footer and credits styles */
  .footer {
    position: fixed;
    z-index: 99;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background: #fff;
    font-size: 0.75rem;
    text-align: right;
    font-weight: 600;
  }