/* --------------------header section---------------------- */
/* Main container */
.endorMainconatiner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 2rem; */
 
   
  }
  
  /* Content container */
 .endorMainconatiner .contentContainer {
    text-align: center;
    max-width: 800px;
    width: 100%;
   
    
 
  }
  
  /* Heading styles */
  .endorMainconatiner .contentContainer .heading {
    color: #394B99;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 48px;
   padding-top: 15px;
  }
  
  /* Description styles */
.endorMainconatiner .contentContainer  .description {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  margin-bottom: 60px;
  }
  
  /* Image styles */
.endorMainconatiner .contentContainer .image {
    max-width: 100%; /* Make the image responsive */
    height: auto;
    margin-top: 1rem;
    object-fit: contain;
  }
  .featuresConatiner{
    padding: 30px 120px 150px;
  }


          /* Features container    --------------         section--------*/
          .featuresContainerWorkflow {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding: 0px 120px;
            margin-bottom:160px;
            margin-top: 160px;
           
          }
          
          /* Each feature section */
          .featuresContainerWorkflow .featureSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5rem;
            flex-wrap: wrap;
            
           
          }
          
          .leftImage {
            flex-direction: row;
          }
          
          .rightImage {
            flex-direction: row-reverse;
          }
          
          /* Text content styles */
          .textContent {
            flex: 1;
          }
          
          .featureLabel {
            color: #272727;
            font-kerning: none;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px; /* 133.333% */
            position: relative;
            text-align: left;
          }
          
          .featureLabel::after {
            content: '';
            display: block;
            width: 80px;
            height: 3px;
            background-color: rgba(79, 99, 190, 1);
            margin-top: 24px;
            animation: growUnderline 3s ; /* Animation applied */
            margin-bottom: 24px;
          }
          @keyframes growUnderline {
            0% {
                width: 0; /* Start with no underline */
            }
            100% {
                width: 80px; /* Grow to full width */
            }
        }
          .featureDescription {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 200% */
          }
          
          /* Image container styles */
          /* Image container styles */
          .imageContainer {
            flex: 1;
            display: flex;
          }
          
          .leftImage .imageContainer {
            justify-content: flex-end; /* Align image to the left */
          }
          
          .rightImage .imageContainer {
            justify-content: flex-start; /* Align image to the right */
          }
          
          .featureImage {
            max-width: 400px; /* Set maximum width for the image */
            height: auto; /* Maintain aspect ratio */
            object-fit: cover; /* Ensure image fits nicely */
          }

          .headerimageworkflow{
            position: relative;
          }
          .headerimgright{
            position: absolute;
            right: 0;
            z-index: -1;
            right: 9%;
            top: 8%;
          }
          .headerimgleft{
            position: absolute;
            left: 0;
            z-index: -1;
            left: 5%;
    top: 11%;
          }
          @media(max-width:600px){
            .endorMainconatiner{
              padding-left: 30px;
              padding-right: 30px;
            }
            .featuresContainerWorkflow{
              padding: 0px 30px;
            }
          }