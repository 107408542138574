// Scoped Variables Approach
.map-component {
  // Component-specific custom properties (variables)
  --map-color-white: #fff;
  --map-color-black: #000;
  --map-color-text: #282828;
  --map-color-yellow: #ffdf1c;
  --map-color-yellow-hover: #ccb216;
  --map-color-light-blue: #80c3d9;

  // Breakpoints
  --map-break-mobile: 20em;     // 320px
  --map-break-tablet: 37.5em;   // 600px
  --map-break-desktop: 60em;    // 960px
  --map-break-large: 87.5em;    // 1400px

  // Typography
  --map-font-size-base: 16px;
  --map-line-height-base: 1.5;

  // Utility Mixins
  @mixin responsive-center {
    margin-left: auto;
    margin-right: auto;
  }

  @mixin media-query($breakpoint) {
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  }

  // Conversion Function
  @function to-em($pixels, $context: 16) {
    @return #{$pixels / $context}em;
  }

  // Base Styles for Map Component
  * {
    box-sizing: border-box;
  }

  #places-map {
    position: relative;
    width: 100%;
    padding: to-em(52) to-em(25);
    color: var(--map-color-text);

    @include media-query(var(--map-break-desktop)) {
      max-width: to-em(1400);
      @include responsive-center;
    }

    // Map Wrapper
    .map {
      position: relative;
      width: 100%;
      max-width: to-em(903);
      @include responsive-center;

      // Map Image
      .map-image {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
      }

      // Pin Container
      .map-pins {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      // Pin Wrapper
      .map-pin-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
           
        &:hover {
          transform: scale(1.1);
        }
      }

      // Location Marker
      .map-location-marker {
        width: to-em(60);
        height: to-em(60);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        z-index: 10;
        transition: transform 0.3s ease;
     

        // Pulsating Effect
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: to-em(31);
          height: to-em(31);
          border: 2px solid var(--map-color-white);
          border-radius: 50%;
         
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }

  // Specific Location Marker Positioning
  #places-map .map .map-pin-wrapper {
    &.marker-san-francisco { left: 6%; top: 30%; }
    &.marker-denver { left: 13%; top: 29%; }
    &.marker-argentina { left: 29.5%; bottom: 21.5%; }
    &.marker-retailcloud { left: 64%; top: 42%; }
    &.marker-aon { left: 17.5%; top: 50%; }
    &.marker-peterborough { left: 45.5%; top: 20%; }
    &.marker-vilnius { left: 52%; top: 18%; }
    &.marker-singapore { left: 79%; bottom: 42%; }
    &.marker-manila { left: 84.5%; top: 48%; }
    
  }

  // Animations (Namespaced)
  @keyframes map-pulsate {
    0% {
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 0;
    }
  }

  @keyframes map-bounce-in-down {
    0% {
      opacity: 0;
      transform: translateY(-2000px);
    }
    60% {
      opacity: 1;
      transform: translateY(30px);
    }
    80% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  // Animation Classes
  .map-animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .map-bounce-in-down {
    animation-name: map-bounce-in-down;
  }

  // Responsive Adjustments
  @media (max-width: 599px) {
    #places-map .map .map-location-marker {
      width: to-em(20);
      height: to-em(20);
    }
  }

  // Accessibility and Focus States
  .map-location-marker {
    &:focus {
      outline: 2px solid var(--map-color-yellow);
      outline-offset: 2px;
    }
  }

  // Print Styles
  @media print {
    #places-map {
      .map-pins,
      .map-location-marker:after {
        display: none;
      }
    }
  }
}