.counterContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background-color: #4f63be; */
    padding: 40px 0px ;
    color: white;
  }
  
  .counterData {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    gap: 3vh;
  }
  
  .counterItem {
    text-align: center;
  }
  
  .icon {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .icon img {
    width: 100%;
    max-width: 80px;
    height: auto;
  }
  
  .number {
      color: #394B99;
      font-family: Inter;
      font-size: 48px;
      font-weight: 700;
      line-height: 58.09px;
      text-align: center;
      margin-bottom: 20px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
  }
  .counterItem .label {
 
      color: #394B99;
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

  }
  
  @media (max-width: 895px) {
    .counterData {
      grid-template-columns: repeat(2, minmax(30px, 1fr));
      gap: 7vh;
    }
  }
  
  @media (max-width: 459px) {
    .counterData {
      gap: 5vh;
    }
  
    .number {
      font-size: 30px;
    }
  
    .counterItem .label {
      font-size: 18px;
    }
  }
  
  @media (max-width: 385px) {
    .counterData {
      gap: 3vh;
    }
  
    .number {
      font-size: 24px;
    }
  
    .counterItem .label {
      font-size: 16px;
    }
  }
  