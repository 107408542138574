/* Wrapper for the entire page */
.functionpageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 160px;
}

/* Heading Style */
.functionpageContainer .heading {
  font-size: 40px;
  font-weight: 600;
  color: #394b99;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 140px;
}

/* Content Section */
.functionpageContainer .contentContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.contentContainer .imageContainer {
  margin: auto;
  max-width: 400px;
  min-width: 200px;
  width: 50%;
}

.imageContainer {
  width: 50%;
}

.imageContainer .image {
  width: 100%;

  height: auto;
  border-radius: 12px;

}

.textContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.textContainer .label {
  color: #272727;
  font-kerning: none;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
}

.description {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 175% */
}

/* Grid Section */
.gridContainer {
  display: grid;

  /* grid-template-columns: repeat(6, 1fr); */
  /* Adjust for 3 items per row */
  gap: 20px;
  margin-top: 100px;

  width: 100%;
}

.grid_div {

  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

}

.workflow_div {

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.gridItemImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 12px;

}

.gridLabel {
  color: #272727;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 200% */
  margin-top: 20px;
}

.hover_effect {
  transition: all 0.3s ease;
  /* Smooth transition */
}

.gridItemImage:hover .hover_effect path {
  fill: white !important;
  /* Change SVG color to white when the parent is hovered */
}

.gridItemImage:hover {
  /* background: linear-gradient(180deg, #E27DDD 0%, #EBB6EC 100%); */
  cursor: pointer;
}

.integrationgrid {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin-top: 20px;
  width: 100%;
  transition: transform 0.5s ease-in;
}

/* Box Container for Card */


/* Card Rectangle */
.rectangle {
  background-color: #ffffff;
  border: 2px solid #394b991a;
  border-radius: 24px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 16px;
  box-shadow: 0px 0px 8px 0px #00000026;
  overflow: hidden;
}

/* Image Style */
.rectangle .image {
  width: 100%;
  max-width: 120px;
  object-fit: cover;
  border-radius: 12px;
}

/* Label Style */
.label {

  width: 100%;
  text-align: left;
  margin-top: 8px;
}

.label .textWrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.workflowsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 180px;

}

.workflowsContainer>h2 {
  color: #394B99;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Description Style */
.description {
  margin-top: 8px;
  width: 100%;
  text-align: left;
}

.description .textWrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  word-wrap: break-word;
  /* Forces text to wrap */
  overflow: hidden;
  /* Hides any text that overflows */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) for overflowing text */
  white-space: normal;
  /* Ensures text wraps to a new line */
}

.noDataFound {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
  padding: 5rem 20px;
}

.loading_div {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  padding: 5rem 20px;
}

.activeCard {
  transform: scale(1.2);
  /* Larger scale for active card */

  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  /* Smooth transition */
}

@media screen and (max-width: 1300px) {

  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
    /* Adjust for 3 items per row */
  }

  .textContainer {
    width: auto;
  }

  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 720px) {

  /* .gridContainer {
    grid-template-columns: repeat(2, 1fr);

  } */

  .functionpageContainer {
    margin: 0px 60px;
  }

}

@media screen and (max-width: 862px) {


  .functionpageContainer {
    margin: 0px 60px;
  }

}

@media screen and (max-width: 660px) {


  .functionpageContainer {
    margin: 0px 20px 160px;
  }

}

@media screen and (max-width: 600px) {



  /* .functionpageContainer .contentContainer {
        grid-template-columns: 1fr;
      } */
  .imageContainer {
    max-width: 300px !important;
  }

  .textContainer {
    margin: 30px 0px;
  }
}