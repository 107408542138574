.contactuscontainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background: linear-gradient(to right, #f3f4fe, #fef3fc); */
  /* padding: 0px 120px; */
  margin-bottom: 100px;
}

.contactuscontainer .title {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #394B99;
  margin-bottom: 80px;

}

.formContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
  width: 100%;


  border-radius: 12px;
}

.contactusform {

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}

.contactusform .inputRow {
  display: flex;
  gap: 20px;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inputGroup .label {
  font-size: 14px;
  color: #959595;
  ;
  margin-bottom: 8px;
}


.inputGroup .textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 24px;
  background-color: #ffffff;
  transition: border 0.3s ease;
  box-shadow: 0px 0px 10px 0px #00000014;

}

.contactusinput::placeholder,
.textarea::placeholder {
  color: #C0C0C066;
  /* Placeholder color */

}

.inputGroup .textarea:focus {
  outline: none;
  border-color: #2b2b73;
}

.inputGroup .textarea {
  height: 100px;
  resize: none;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 16px;
}


.contactusButton {
  color: #ffffff;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #4F63BE;
  ;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton {
  border: none;
  background-color: none;
  color: #a2a2a2;
  background-color: #f3f4fe;
  font-weight: 600px;

}

.loginButton:hover {
  background-color: #f3f4fe;
}


.contactusButton:hover {
  background-color: #4040c5;
}

.partnerimageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnerimageContainer .image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.contactusinput {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  transition: border-bottom 0.3s ease;
}

.contactusinput:focus {
  outline: none;
  border-bottom: 2px solid #383838;
  /* Highlight color on focus */
}

.required {
  color: red;
  font-weight: 500;
}

.inputError {
  border-bottom: 1px solid red;
}

.contactusimageContainer {
  width: auto;
}

.contactuscontainer .image {
  min-width: 400px;
  width: 100%;
}

@media(max-width:1208px) {
  .contactusform {
    width: 100%;
  }
}

.responseMessage {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
  position: relative;
  justify-content: space-between;
  width: 50%;
  font-size: 14px;
  color: #333;
}

.closeButton {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.closeButton:hover {
  color: #555;
}




.iconWrap {
  text-decoration: none !important;
  color: inherit;
}

.footerBottom {
  text-align: center;
  display: flex;
  text-align: left;

  background-color: #FFF;
  gap: 40px;
  margin: auto;
  /* max-width: 1440px; */
  /* background-color: #FFF; */

}

.bottomSection {
  /* max-width: 1200px; */
  margin: auto;
  background-color: #FFF;

}

.bottomLine {
  border: 1px solid #d9d9d9;
  margin-top: 0px;
  margin: 0px 120px;

}

.footerlinksSection {
  display: flex;

  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 120px;
  justify-content: space-between;
}

.footerIcons .LogoWrapper {
  margin-right: 20px;
}

.footerBottomleft {
  display: flex;

  gap: 10vh;
  margin-right: 120px;
  padding: 30px 0px;
}

.LogoWrapper>a {
  color: transparent !important;
}

.addressParagraph {
  color: #272727;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 150% */
  letter-spacing: 0.323px;
}

.usAddress {
  background: #F0F8FF;
  flex: 1;
  padding: 30px 10px 10px min(120px, 10vw);
}

.cochinAdress {
  margin-left: 80px;
}

.indiaAdress {
  flex: 3;
  padding: 30px 120px 30px 0px;
}

.calicutAdress {
  width: 50%;
  border-right: 1px solid #D9D9D9;
}

.india {
  text-align: center;
  margin-bottom: 20px;
}

.commonemail {
  text-align: center;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  /* margin-bottom: 20px; */
}

.states {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;

}

.indiaAdressContainer {
  display: flex;
}

@media (max-width: 600px) {

  .contactusform {
    width: 100%;
  }

  .contactuscontainer .image {
    min-width: 200px;
    width: 100%;
  }

  .usAddress {
    padding: 30px;
  }

  .cochinAdress {
    padding-right: 0px;
    margin-left: 0px;

  }

  .india {
    text-align: left;
    margin-bottom: 20px;
  }

  .indiaAdress {
    padding: 30px;
  }

  .indiaAdressContainer {
    flex-direction: column;
  }

  .calicutAdress {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .footerBottom {
    text-align: center;
    display: flex;
    text-align: left;
    flex-direction: column;
    background-color: #FFF;
    gap: 0px;
    margin: auto;
  }

  .footerlinksSection {
    justify-content: center;
  }

  .actionfibottom {
    justify-content: center;
  }

  .footerBottomleft {
    margin-left: 0px;
    gap: 4vh;
    flex-direction: column;
  }

  .usAddress {
    width: auto;
    margin-bottom: 10px;

  }

  .footerlinksSection {
    padding: 30px;
  }

  .bottomLine {
    margin: 0px 30px;
  }
}


@media (min-width: 600px) and (max-width: 1240px) {
  .cochinAdress {
    margin-left: 40px;
  }

  .calicutAdress {
    width: auto;
  }
}

@media (min-width: 600px) and (max-width: 860px) {
  .cochinAdress {
    margin-left: 10px;
  }

  .calicutAdress {
    width: auto;
  }
}

@media (min-width: 1600px) {
  .usAddress {
    padding: 30px 10px 10px calc(120px + (100vw - 1600px) * 0.4);
  }
}