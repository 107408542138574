.customSearchContainer {
    /* Overall container styles */
    display: flex;
    align-items: center;


  
    padding: 8px 15px;
    border-radius: 24px;
background: #FFF;
box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
}

.searchInput {
    /* Input field styles */
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
}

.searchButton {
    /* Button styles */
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
}

.searchIcon {
    /* Icon styles */
    width: 20px;
    height: 20px;
    fill: #666;
}