.actionfibottom {
  display: flex;
}

.iconWrap>a {
  text-decoration: none !important;
  color: inherit;
}

.iconWrap {
  margin: 8px 0px;
}

.footerBottom {
  text-align: center;
  display: flex;
  text-align: justify;

  gap: 40px;
  margin: auto auto;
  max-width: 1440px;
  background-color: #FFF;

}

.bottomSection {
  max-width: 1440px;
  margin: auto;
  background-color: #FFF;
  border-top: 1px solid #D9D9D9;
  /* width: 100%;
  padding: 0px 120px; */

}

.bottomLine {
  border: 1px solid #d9d9d9;
  margin-top: 0px;
  margin: 0px 120px;

}

.footerlinksSection {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 0px;
  justify-content: space-between;
}

.footerIcons .LogoWrapper {
  margin-right: 20px;
}

.footerBottomleft {
  display: flex;

  gap: 10vh;
  margin-right: 120px;
  padding: 30px 0px;
}

.LogoWrapper>a {
  color: transparent !important;
}

.addressParagraph {
  color: #272727;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.323px;
}

.usAddress {
  background: #F0F8FF;
  width: 30%;
  padding: 30px 10px 10px 120px;



}

.cochinAdress {
  padding-right: 120px;
}

@media (max-width: 600px) {

  .usAddress {
    padding: 30px;
  }

  .cochinAdress {
    padding-right: 0px;
  }

  .footerBottom {
    justify-content: center;
    text-align: -webkit-match-parent;
    ;
  }

  .footerlinksSection {
    justify-content: space-around;
  }

  .actionfibottom {
    justify-content: center;
  }

  .footerBottomleft {
    margin-left: 0px;
    gap: 4vh;
    flex-direction: column;
  }

  .footerBottom {
    display: block;
    margin: auto auto;
  }

  .usAddress {
    width: auto;
    margin-bottom: 10px;

  }

  .footerlinksSection {
    padding: 50px 0px;
  }

  .bottomLine {
    margin: 0px 30px;
  }

  .bottomSection {
    padding: 0px;
  }
}

@media (max-width:300px) {
  .bottomSection {
    padding: 0px;
  }

  .footerlinksSection {
    padding: 30px 0px;
  }
}

@media (max-width:900px) {
  .bottomSection {
    padding: 0px;
  }
}