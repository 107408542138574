.featuresHeading {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #394B99;
}

.featuresContainer {
  width: 100%;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
}

.featureSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.featureSection:nth-child(even) {
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.textContent {
  width: 45%;
}

.heading {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.featureLabel {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: relative;
}

.featureLabel::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  /* Adjust this value for the distance between the label and the underline */
  width: 80px;
  height: 3px;
  background-color: rgba(79, 99, 190, 1);
  /* Underline color */
  animation: growUnderline 3s;
  /* Animation applied */
}

@keyframes growUnderline {
  0% {
    width: 0;
    /* Start with no underline */
  }

  100% {
    width: 80px;
    /* Grow to full width */
  }
}

.featureDescription {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 3rem;
}

.imageContainer {
  width: 45%;
  position: relative;

}

/* .featureImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  } */
.featureImage {
  width: 100%;
  /* Adjust the image width */
  z-index: 1;
  height: auto;
  /* Keep the aspect ratio */
}

/* Alternative styles for alternating left and right layouts */
.leftImage .imageContainer {
  order: 1;
}

.rightImage .imageContainer {
  order: 2;
}

.circle {
  position: absolute;
  z-index: -1;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.rectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;


}

/* Unique Animation for Circle One */
.circleOne {
  animation: randomMoveOne 5s infinite;
}

/* Unique Animation for Circle Two */
.circleTwo {
  animation: randomMoveTwo 5s infinite;
  right: 0;
}

/* Unique Animation for Circle One */
.circleOne2 {
  animation: randomMoveOne2 5s infinite;
}

/* Unique Animation for Circle Two */
.circleTwo2 {
  animation: randomMoveTwo2 5s infinite;
  right: 0;
}

/* Unique Animation for Circle One */
.circleOne3 {
  animation: randomMoveOne3 5s infinite;
}

/* Unique Animation for Circle Two */
.circleTwo3 {
  animation: randomMoveTwo3 5s infinite;
  right: 0;
}

/* Keyframes for Circle One */
@keyframes randomMoveOne {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(150px, 50px);
  }

  50% {
    transform: translate(-40px, 50px);
  }

  75% {
    transform: translate(20px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle Two */
@keyframes randomMoveTwo {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-50px, -80px);
  }

  50% {
    transform: translate(50px, -30px);
  }

  75% {
    transform: translate(-30px, 40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle One */
@keyframes randomMoveOne2 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(10px, -40px);
  }

  50% {
    transform: translate(70px, 70px);
  }

  75% {
    transform: translate(10px, 40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle Two */
@keyframes randomMoveTwo2 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(30px, -80px);
  }

  50% {
    transform: translate(40px, 20px);
  }

  75% {
    transform: translate(-50px, 80px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle One */
@keyframes randomMoveOne3 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(40px, 50px);
  }

  35% {
    transform: translate(-10px, 30px);
  }

  65% {
    transform: translate(40px, -40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle Two */
@keyframes randomMoveTwo3 {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(30px, 40px);
  }

  35% {
    transform: translate(-50px, 30px);
  }

  65% {
    transform: translate(-20px, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 768px) {
  .featureSection {
    flex-direction: column;
    align-items: center;
  }

  .textContent,
  .imageContainer {
    width: 100%;
    margin-bottom: 1rem;
  }

  .heading {
    font-size: 1.5rem;
  }

  .featureLabel {
    font-size: 1rem;
  }

  .featureDescription {
    font-size: 0.9rem;
  }
}

@media (max-width: 600px) {
  .featuresContainer {
    padding: 3rem 0px;
  }
}