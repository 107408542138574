/* Main container */
.careersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 160px;

}

/* Content container */
.careersContainer .contentContainer {
  text-align: center;
  max-width: 800px;
  width: 100%;



}

/* Heading styles */
.careersContainer .contentContainer .heading {
  font-size: 40px;
  font-weight: bold;
  color: #394B99;
  margin-top: 0px;
  margin-bottom: 40px;
}

/* Description styles */
.careersContainer .contentContainer .description {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 68px;
}

/* Image styles */
.careersContainer .contentContainer .image {
  /* max-width: 700px; */
  height: auto;

  width: 100%;
  object-fit: contain;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.heading2 {
  margin-top: 80px;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 44px;
}

@media(max-width:600px) {
  .careersContainer {
    margin: 0px 30px;
  }
}