.solutionsContainer {
  display: flex;
  /* Use flexbox for horizontal layout */
  justify-content: space-around;
  /* Evenly space out the solution boxes */
  align-items: flex-start;
  /* Align items at the top for consistent alignment */
  gap: 30px;
  /* Add some space between the boxes */
  flex-wrap: wrap;
  width: 100%;
  /* Allow wrapping if the container is too narrow */
}

.solutionBox {
  display: flex;
  /* Use flexbox inside each solution box */
  flex-direction: column;
  /* Stack items vertically (image, label, content) */
  align-items: flex-start;
  /* Center-align items horizontally */
  justify-content: space-between;
  /* Align items to the top */
  border-radius: 8px;
  /* Optional: rounded corners for boxes */
  padding: 20px;
  /* Padding inside the box */
  width: 180px;
  /* Set a fixed width for each box */
  height: 250px;
  /* Allow the box height to adjust based on content */
  cursor: pointer;
}

.drop>img {
  width: 100px;
  /* Set a fixed width for the image */
  height: 100px;
  /* Set a fixed height for the image */
  object-fit: contain;
  /* Ensure the image fits inside the container without stretching */
  /* margin-bottom: 49px; */
  /* Space between the image and the label */
}

.solutionBox>h3 {
  color: #272727;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  margin: 0px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  /* Set a dark color for the label */
}

.solutionBox>p {
  font-size: 14px;
  /* Font size for the content */
  color: #6D6D6D;
  /* Set a lighter color for the content */
  text-align: center;
  /* Ensure content is centered */
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 16px;
}


.textdata {
  display: flex;
  /* Use flexbox to align label and content vertically */
  flex-direction: column;
  align-items: left;

  height: -webkit-fill-available;
  /* Center-align the text within the box */
  justify-content: flex-start;
  /* Align items to the top */
}

.textdata>h3 {
  color: #272727;
  margin: 0px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.textdata>p {
  font-family: Inter;
  font-size: 16px;
  margin-top: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6D6D6D;

}

.learnMoreText {
  text-align: center;
  color: #000000;
  /* Optional color for "Learn More" */
  /* margin-top: auto; Pushes it to the bottom of the solution box */
  height: 30px;
  /* Ensures consistent height */
  line-height: 30px;
  /* Centers text vertically */
  position: absolute;
  /* Keeps it fixed at the bottom */
  margin-left: 146px;
  margin-top: 214px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;

}

.drop {
  width: 100px;
  height: 200px;
  position: relative;
  /* box-shadow: inset 20px 20px 20px rgba(0, 0, 0, 0.1),
    25px 35px 20px rgba(0, 0, 0, 0.1), 25px 30px 30px rgba(0, 0, 0, 0.1),
    inset -20px -20px 25px rgba(255, 255, 255, 0.8); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem 0;
  margin-bottom: 39px;
  transition: border-radius 1s;
  overflow: hidden;
  text-align: center;
  animation: drops 10s infinite alternate ease-in-out;
}

.drop {
  border-radius: 35% 65% 30% 70% / 30% 60% 40% 70%;
}

@keyframes drops {
  0% {
    border-radius: 35% 65% 30% 70% / 30% 60% 40% 70%;
  }

  25% {
    border-radius: 60% 40% 70% 30% / 50% 40% 60% 30%;
  }

  50% {
    border-radius: 20% 80% 40% 60% / 60% 30% 50% 40%;
  }

  75% {
    border-radius: 70% 30% 60% 40% / 40% 70% 30% 60%;
  }

  100% {
    border-radius: 35% 65% 30% 70% / 30% 60% 40% 70%;
  }
}

/* @keyframes drops {
  0% {
    border-radius: 35% 65% 30% 70% / 30% 60% 40% 70%;
  }

  25% {
    border-radius: 60% 40% 70% 30% / 50% 40% 60% 30%;
  }

  50% {
    border-radius: 20% 80% 40% 60% / 60% 30% 50% 40%;
  }

  75% {
    border-radius: 70% 30% 60% 40% / 40% 70% 30% 60%;
  }

  100% {
    border-radius: 35% 65% 30% 70% / 30% 60% 40% 70%;
  }
} */


@media(max-width:400px) {
  .solutionBox>img {
    width: 70px;

    height: 70px;


  }
}