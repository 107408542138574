.testimonialMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:0;
  max-width: 90%;
  
  margin: auto;
}
.testimonial_inner{
  position: relative;
  display: flex;
  width: 100%;
}
.testimonialSection {
  text-align: center;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 315px; /* Increased height for the entire section */
}

.carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
  transition: transform 0.5s ease; /* Smooth animation for the movement */
}

.card {
  cursor: pointer; 
  flex-shrink: 0;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  opacity: 0.5;
  transition: all 0.5s ease; /* Smooth transition for scaling and opacity */
  position: absolute;
  overflow: hidden;
  transform: scale(0.9);
  height: 250px; /* Default height for cards */
  bottom: 0;
}

/* Center card */
.card.center {
  width: 384px;
  opacity: 1;
  transform: scale(1.1) translateY(-30px); /* Increased translateY for a bigger effect */
  z-index: 2;
  height: 315px; /* Increased height for the center card */
}

/* Left card */
.card.left {
  width: 307px;
  transform: translateX(-120%) scale(0.9);
  z-index: 1;
  
}

/* Right card */
.card.right {
  width: 307px;
  transform: translateX(120%) scale(0.9);
  z-index: 1;

}

/* Hidden cards */
.card.hidden {
  transform: translateX(200%);
  opacity: 0;
  pointer-events: none;
  z-index: 0;
}

/* Hover effect for the full description */
.card:hover .fullDescription {
  display: block;
}

.clientInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -10px;
  border-radius: 0px 0px 0px 10px;
  background: #F1F7FF;
  bottom: 0;
  width: 100%;
  position: fixed;
  min-height: 80px;
}

.clientInfo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  padding-left: 15px;
}

.fullDescription {
  display: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

blockquote {
  color: #4A4A4A;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 215.385% */
  margin-bottom: 10px;
}

.dots {
  margin: 20px 0;
}

.dot {
  width: 10px;
  height: 10px;
  background: lightgray;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.dot.activeDot {
  background: #7398C7;
}

.fullTitle {
    width: 100%;
    display: flex;                /* Use flexbox for layout */
    justify-content: space-between; /* Align text to left and image to right */
    align-items: center;          /* Vertically align items */
    background: linear-gradient(180deg, #FFFFFF 43.9%, #E7ECF6 100%);  /* Linear gradient background */
               /* Add some padding */
    border-radius: 8px;           /* Optional: add rounded corners */
    text-align: center;
  
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    /* color: #333; */
    flex-direction: row;
    flex: 1 1;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.03);
  }
  .fullTitle_imgcontainer{
    position: relative;
    }
    .blue_circle{
      position: absolute;
      right: 71px;
     width: 220px;
      top: -30px;
      animation: randomMoveTwo 5s infinite;
  
    }
    .pink_circle{
      position: absolute;
      right: -23px;
      top: 181px;
      animation: randomMoveOne 5s infinite;
    }
    /* Keyframes for Circle One */
@keyframes randomMoveOne {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(20px, 50px);
  }

  50% {
    transform: translate(-20px, 30px);
  }

  75% {
    transform: translate(10px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Keyframes for Circle Two */
@keyframes randomMoveTwo {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-30px, -10px);
  }

  50% {
    transform: translate(10px, -30px);
  }

  75% {
    transform: translate(-30px, 40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

  .dots {
    /* Add margin and padding as necessary */
    text-align: center;
    margin-top: 20px;
    background-color: transparent;
  }

  
  .fullTitle_content >p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    flex: 1;                      /* Allow text to take up available space */
    
    color: #4A4A4A;                  /* Set text color */
    margin: 0;                    /* Remove any default margin */
    
    align-self: flex-start;
  }
  
  .fullTitle img {
                   /* Set the image width */
    height: auto;                 /* Maintain aspect ratio */
    border-radius: 8px;           /* Optional: round image corners */
  }
  .bottomname{
    color: #404040;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  }
  .bottomDesignmation{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .dots_fullTitle{
   display: flex;
   flex-direction: column;
  }

  /* Arrows for navigation */
  
  .leftButton,
  .rightButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 20px;
    background-color: transparent;
    
    
  }
  
  .leftButton:hover,
  .rightButton:hover {
    background-color: #f0f0f0;
  }
  
  .leftButton {
    left: -50px; /* Adjust to position outside the carousel */
  }
  
  .rightButton {
    right: -50px; /* Adjust to position outside the carousel */
  }
  .fullTitle_content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 40px;
    padding-bottom: 0px;
  }
  @media (max-width: 1200px) {
    .fullTitle_content{
      flex-direction: column;
    }
  }
  
  @media (max-width: 600px) {
   .card.center{
    width: 250px;
   }
   .testimonialMain{
    max-width: 85%;
   }
   .clientInfo{
    padding-left: 9px;
   }
  }
