.Signupcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(to right, #f3f4fe, #fef3fc); */     
    padding: 0px 120px;
    margin-bottom: 160px;
  }
  
  .Signupcontainer .title {
    font-size: 32px;
    font-weight: bold;
    color: #394B99;
    margin-bottom: 80px;
    margin-top: 0px;
  }
  
  .formContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
    width: 100%;
    padding: 20px;
   
    border-radius: 12px;
  }
  
  .signUpform {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .signUpform .inputRow {
    display: flex;
    gap: 20px;
  }
  
  .inputGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup .label {
    font-size: 14px;
    color:#404040;
    margin-bottom: 8px;
  }
  

  .inputGroup .textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 24px;
    background-color: #ffffff;
    transition: border 0.3s ease;
    box-shadow: 0px 0px 10px 0px #00000014;

  }
  
  .Signupinput::placeholder,
  .textarea::placeholder {
  color: #C0C0C066; /* Placeholder color */
  
}
 .inputGroup .textarea:focus {
    outline: none;
    border-color: #2b2b73;
  }
  
 .inputGroup .textarea {
    height: 100px;
    resize: none;
  }
  
  .buttonRow {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  

  .SignupButton {
    color: #ffffff;
    padding: 12px 24px;
    font-size: 16px;
    background-color: #4F63BE;    ;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .loginButton {
    border: none;
    background-color: none;
    color: #a2a2a2;
    background-color: #f3f4fe;
    font-weight: 600px;

  }
  
  .loginButton:hover {
    background-color: #f3f4fe;
  }
  

  .signupButton:hover {
    background-color: #4040c5;
  }
  .required {
    color: red;
    font-weight:500;
  }      
  .inputError{
    border-bottom: 1px solid red !important;
  }
  .partnerimageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 .partnerimageContainer .image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  .Signupinput
{
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  transition: border-bottom 0.3s ease;
}

.Signupinput:focus
 {
  outline: none;
  border-bottom: 2px solid #383838; /* Highlight color on focus */
}

.responseMessage {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
  position: relative;
  justify-content: space-between;
  width: 50%;
  font-size: 14px;
  color: #333;
}

.closeButton {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.closeButton:hover {
  color: #555;
}
@media(max-width:600px){
  .Signupcontainer{
    padding: 0px 30px;
  }
  .formContainer{
    margin: auto;
  }
}