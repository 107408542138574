/* navbar.module.css */

.navbar {

  /* left: 120px;  */
  display: flex;
  justify-content: space-between;
  /* Space between logo and links */
  align-items: center;
  /* Vertically center content */
  height: 60px;
  /* width: calc(100% - 120px);  */
  color: white;

  padding-top: 15px;
  left: 0;
  right: 0;
  padding-right: 120px;
  padding-left: 120px;
  background-color: white;
  z-index: 9999;
  /* Navbar always above the page */
  max-width: 1440px;
  margin: auto;
  position: fixed;




}

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #272727;
  font-weight: bold;
}

/* .navbar__logo {

    height: 45px;
  } */

.navbar__logoImg {
  width: 133px;
  max-height: 45px;
}

.navbar__links {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 45px;
  /* Spacing between list items */
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.navbar__item {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar__item>a {
  text-decoration: none;
  color: #272727;
  font-family: 'Inter';
  font-size: 16px;
  padding: 8px 10px;
  font-weight: 400;
  position: relative;
  /* Required for positioning the underline */
  display: inline-block;
  /* Ensures that the underline works on the full width of the link */
}

.navbar__item>a:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 3px;
  background-color: #7398C7;
  border-radius: 5px;
  z-index: 10;
}

.navbar__item a.active::after {
  content: "";
  /* Create the underline */
  position: absolute;
  left: 0;
  bottom: -10px;
  /* Adjust space below the text */
  width: 100%;
  height: 3px;
  /* Thickness of the underline */
  background-color: #7398C7;
  /* Stroke color */
  border-radius: 5px;
  /* Optional for rounded stroke */
  z-index: 10;
}

/* .navbar__item a.active {
    color: #7398C7; /* Optional: Change color of the active link */
/* } */
/* Optional: Responsive Design */
/* @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar__links {
      flex-direction: column;
      gap: 10px;
    }
  
    .navbar__logo {
      margin-bottom: 10px;
    }
  } */
.dropdownWrapper {
  position: absolute;
  top: 100%;
  /* width: 100%; */
  z-index: 9999;
  /* Ensure dropdown is above everything else */
  background-color: #f9f9f9;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* .solution_position {
  left: calc(60px + 37vw);
}

.platform_position {
  left: calc(60px + 42vw);
} */

/* .navbar__link.active {
    color: #7398C7; /* Optional: Change color of the active link */


.navbar__links__visible {
  display: none;
}


.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 1120px) {
  .navbar__links {
    display: none;

  }

  .navbar__links__visible {
    display: block !important;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: auto;
    width: 300px;
    height: 100vh;
    /* height: auto; */
    /* margin-right: 120px; */
    margin-left: 0px !important;
    margin-block-start: 0px;
    padding-inline-start: 10px;
    right: 0;
    overflow: scroll;
    padding: 30px 2rem;
    gap: 10px;
    z-index: 10;
    min-height: auto;
    transition: all, .3s ease-in-out;
    transform: translateX(0px) translateY(0px);
    /* max-height: 300px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: -300px
  }

  .navbar__item a.active::after {
    bottom: 0px;
  }

  .navbar__item>a:hover::after {
    bottom: 0px;
  }

  .hamburger {
    display: block;
  }

  .dropdownWrapper {
    left: -10px;
    /* Align slightly to the left of the dropdown for better appearance */
    width: calc(100% - 20px);
    /* Ensure dropdown doesn't exceed the container width */
    margin-top: 10px;
    /* Add a small gap between the dropdown and navbar links */
  }

  .navbar__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .dropdownWrapper {

    /* position: absolute; */
    top: 100%;
    left: 0;
    width: 100%;
    display: contents;
    z-index: 9999;
    background-color: #f9f9f9;
  }


}

@media(max-width:600px) {
  .navbar {
    padding: 15px 30px;

  }

  .navbar__links__visible {
    width: 300px;
  }
}