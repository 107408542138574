.integrationContainer .heading {
  color: #394B99;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  overflow: hidden;
  width: 100%;
  margin-bottom: 55px;
}
.integrationContainer{
  margin-top: 160px;
}
.sliderWrapper {
  position: relative;
  width: 100%;
  min-height: 550px; /* Changed from height to min-height */
  overflow: hidden;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
  position: absolute;
  width: 100%;
  transition: transform 2.5s ease-in-out;
}
/* carousel.module.css */
.carousel_container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  overflow: hidden !important;
}
.carousel_container .slick-slide{
  margin: 0 10px;
}
.carousel_item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 20px;
  width: auto !important;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.item_content h3 {
  margin: 0;
}

/* Add a responsive adjustment for smaller screens */
@media(max-width:1390px){
  .carousel_container{
    max-width: 800px !important;
  }
}
@media(max-width:900px){
  .carousel_container{
    max-width: 500px !important;
  }
}
@media(max-width:700px){
  .carousel_container{
    max-width: 400px !important;
  }
}
@media(max-width:600px){
  .carousel_container{
    max-width: 300px !important;
  }
}
@media (max-width: 1024px) {
  .sliderWrapper {
    min-height: 600px; /* Allow height to adjust to content on smaller screens */
    height: auto;

  }

  .grid {
   
    
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
}

@keyframes slideLeftCurrent {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInNext {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.grid.sliding {
  animation: slideLeftCurrent 0.5s ease-in-out forwards;
}

.grid.sliding:hover {
  animation: none; /* Disable animation on hover */
}

.grid .nextSlide {
  animation: slideInNext 0.5s ease-in-out forwards;
}
.grid:hover{
  transition: none !important; /* Pause animation on hover */
}
.card:hover {
 
  border: 3px solid #E27DDD;
  animation: animateBorder 1s linear infinite; 
}
/* Rest of your existing CSS remains the same */
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    overflow: hidden;
    transition: transform 0.5s ease;
    background: #fff;
    text-align: center;
    position: relative;
    display: flex !important;
    width: auto !important;
    margin: 20px 10px;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out; /* Smooth hover effect */
    
  }
  
   
  @keyframes animateBorder {
    0% {
      border-color: transparent transparent transparent transparent;
    }
    20% {
      border-color: #E27DDD transparent transparent transparent; 
    }
    40% {
      border-color: #E27DDD #E27DDD transparent transparent; 
    }
    60% {
      border-color: #E27DDD #E27DDD #E27DDD transparent; 
    }
    80% {
      border-color: #E27DDD #E27DDD #E27DDD #E27DDD; 
    }
    100% {
      border-color: transparent transparent transparent transparent; /* Reset */
    }
  }
  .card img {
   
    max-width: 125px;
    max-height: 40px;
    object-fit: contain;
  }
    .cardImage {
      width: 100%;
      height: 88.925px;
      flex-shrink: 0;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      background-color: #f9f9f9;
    }
  
    .card > p {
      font-size: 18px;
      margin: 12px 0;
      color: #000;
      margin: auto;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      padding-top: 24px;
      padding-bottom: 2px;
      text-align: left;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  
    .learnmoreBtn {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      border: none;
      background: transparent;
      cursor: pointer;
      line-height: normal;
      
    }
  .dots {
    display: flex;
    justify-content: center;
    gap: 8px;
   
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ddd;
    cursor: pointer;
  }
  
  .dot.active {
    background: #0073e6;
  }
  .learnmorecontainer{
    padding: 15px 0px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }