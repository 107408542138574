/* RotatingText.module.css */
.RotatingTextcontainer {
    display: flex;
    justify-content: center; /* Center the items */
    flex-direction: column;
    align-items: center;
    /* gap: 20px; */
    width: 100%;
   
    margin: 0 auto;
    transform-origin: center; /* Set the pivot point for rotation */
    animation: rotateAnticlockwise 10s linear infinite; /* Animation for continuous rotation */
  }
  
  .text {
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;   
    margin-top: 8px;
    margin-bottom: 8px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
  }
  
  .highlighted {
    font-weight: bold; /* Highlighted text */
    color:#394B99;
 
  }
  
  .lighter {
    color:#BCC4E6;
    
    opacity: 0.5; /* Dim the non-highlighted text */
  }
  @keyframes dropIn {
    0% {
      opacity: 0;
      transform: translateY(100%); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Move to the center */
    }
  }
  
  @keyframes dropOut {
    0% {
      opacity: 1;
      transform: translateY(0); /* Start from center */
    }
    100% {
      opacity: 0;
      transform: translateY(-100%); /* Move out to top */
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  