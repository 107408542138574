/*AI-Powered Stream Processing to Manage Your IoT Data Needs */
.analyzeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 120px 160px;
 
}

.analyzeHeaderContainer {
  margin-top: 50px;
  margin: 0px 8rem;
  align-items: center;

}

/*----------- header image----------------- */


/* .analyzeContainer .headerImg {

  padding: 1rem 1rem;
} */

.analyzeContainer .headerImg>img {
  max-width: 400px;
}

/* -------------header container----------- */

.analyzeHeaderContainer>h1 {
  color: #394B99;
  font-family: Inter;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;


}

.analyzeHeaderContainer>p {
  color: rgba(0, 0, 0, 1);
  font-family: Inter;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 48px;

}

.analyzeHeaderContainer .explorefurther {
  display: flex;
  align-items: center;

}

.analyzeHeaderContainer .explorefurther>button {
  border-radius: 24px;
  background: #4F63BE;
  box-shadow: 4px 8px 15px 0px #0000001A;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  cursor: pointer;
  padding: 20px 0px;
  color: #fff;
  margin: auto;

  width: 200px;
  border: none;
  margin-bottom: 160px;

}



/* Features container    --------------         section--------*/
.featuresContainer {
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 100%;

}

/* Each feature section */
.featureSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;


}

.leftImage {
  flex-direction: row;
}

.rightImage {
  flex-direction: row-reverse;
}

/* Text content styles */
.textContent {
  flex: 1;
}

.featureLabel {
  color: #272727;
  font-kerning: none;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  /* 133.333% */
}

.featureLabel::after {
  content: '';
  margin-bottom: 24px;
  display: block;
  width: 80px;
  height: 3px;
  background-color: rgba(79, 99, 190, 1);
  margin-top: 24px;
  animation: growUnderline 3s ; /* Animation applied */
}
@keyframes growUnderline {
  0% {
      width: 0; /* Start with no underline */
  }
  100% {
      width: 80px; /* Grow to full width */
  }
}
.featureDescription {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
}

/* Image container styles */
/* Image container styles */
.imageContainer {
  flex: 1;
  display: flex;
}

.leftImage .imageContainer {
  justify-content: flex-end;
  /* Align image to the left */
}

.rightImage .imageContainer {
  justify-content: flex-start;
  /* Align image to the right */
}

.featureImage {
  max-width: 400px;
  /* Set maximum width for the image */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure image fits nicely */
}

@media screen and (max-width: 1140px) {

  .rightImage,
  .leftImage {
    flex-direction: column;
  }

  .analyzeHeaderContainer {
    margin: 0px;
  }

}
@media screen and (max-width: 650px) {

 .analyzeContainer{
  margin: 0px 40px 160px;
 }
 .headerImg >img{
  max-width: 350px;
 }
}